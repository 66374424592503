<template>
  <div
    class="my-3"
    style="height: 300px"
  >
    <map-base
      ref="mapBase"
      :markers="markers"
      :zoom="zoom"
      :mapOptions="mapOptions"
      @handleClickEvent="handleMapClick"
    />
  </div>
</template>
<script>
import L from 'leaflet'
import { defaultMapOptions, defaultMapSetup } from '@/global/common/mapConfig'
import MapBase from '@/global/components/map/MapBase.vue'

export default {
  name: 'CommunicationGeolocationMap',
  components: {
    MapBase
  },
  props: {
    providedGeolocation: {
      type: [Object, null],
      default: null
    },
    viewOnlyMode: {
      type: Boolean,
      default: false
    },
    mapOptions: {
      type: Object,
      default: () => (defaultMapOptions)
    },
    defaultCenter: {
      type: L.LatLng,
      default: () => defaultMapOptions.center
    },
    centerOn: {
      type: Object,
      default: null
    },
    zoomOn: {
      type: Object,
      default: null,
      validator (position) {
        return position.lat && position.lng
      }
    },
    initialZoom: {
      type: Number,
      default: null
    }
  },

  data () {
    return {
      zoom: this.initialZoom || 12,
      minZoom: defaultMapSetup.minZoom,
      markers: null
    }
  },
  watch: {
    centerOn (position) {
      if (position) {
        this.$refs?.mapBase?.$refs?.map?.setCenter(position)
      }
    }
  },

  mounted () {
    this.setProvidedGeolocation()
    if (this.zoomOn) {
      this.$refs?.mapBase?.$refs?.map?.mapObject?.flyTo(this.zoomOn)
    }
    else if (this.centerOn) {
      this.$refs?.mapBase?.$refs?.map?.setCenter(this.centerOn)
    }
    else {
      this.$refs?.mapBase?.$refs?.map?.setCenter(this.defaultCenter)
    }
  },

  methods: {
    handleMapClick (event) {
      if (!this.viewOnlyMode) {
        this.markers = [
          {
            key: event.latlng,
            coordinate: event.latlng,
            draggable: false
          }
        ]
        this.$emit('pinPlaced', this.markers)
      }
    },

    setProvidedGeolocation () {
      if (this.providedGeolocation) {
        this.markers = [
          {
            key: this.providedGeolocation,
            coordinate: this.providedGeolocation,
            draggable: false
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.communication-map {
  height: 100%;
  z-index: 0
}
</style>
