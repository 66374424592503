<template>
<div>
  <v-dialog
    v-model="canShowDialog"
    content-class="elevation-0"
    width="560"
    persistent
  >
    <v-card
      class="v-card"
    >
      <v-card-title
        class="v-card-title"
      >
        {{ title ? title : '' }}
        <v-spacer/>

        <universal-button
          type="base"
          fab
          x-small
          color="#6B7280"
          style="border-radius: 40px;"
          append-icon="mdi-close"
          appendIconColor="#FFFFFF"
          @click="closeDialog"
        />
      </v-card-title>

      <v-card-text
        class="v-card-text"
      >
        <v-row
          style="margin: 0;"
        >
          <v-col>
            <autocomplete-field
              v-model="selectedEmployee"
              :field="autocompleteField"
              :options="autocompleteField.autocomplete_options"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions
        class="v-card-actions"
      >
        <v-row
          style="margin: 0;"
        >
          <v-col>
            <universal-button
              type="cancel"
              class="universal-buttons"
              @click="closeDialog"
            />
          </v-col>
          <v-col>
            <universal-button
              type="save"
              :label="$t('base.send')"
              class="universal-buttons"
              @click="sendForSignature"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import UniversalButton from '@/global/components/buttons/UniversalButton.vue'
import { api } from '@/global/services/api'
import store from '../../../global/store'
import AutocompleteField from '@/global/components/view-layouts/tabs-layout/components/fields/AutocompleteField.vue'
import { isEmpty } from 'lodash'

export default {
  name: 'SendForSignatureDialog',

  components: {
    AutocompleteField,
    UniversalButton
  },

  emits: [
    'close',
    'refresh-instance'
  ],

  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    apiConfig: {
      type: Object,
      default: () => ({})
    },
    documentId: {
      type: [Number, String],
      default: null
    },
    signatureType: {
      type: String,
      default: ''
    },
    additionalObject: {
      type: Object,
      default: () => ({})
    },
    instance: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      canShowDialog: false,
      autocompleteField: {
        key: 'signatory_person_id',
        label: this.$t('fleet/documents/prl_cargo.employee'),
        placeholder: this.$t('fleet/documents/prl_cargo.employee_placeholder'),
        type: 'autocomplete',
        autocomplete_options: {
          module: 'people',
          route: 'people/autocomplete',
          form_value: 'id'
        },
        creatable: true,
        editable: true,
        visible: true
      },
      selectedEmployee: null
    }
  },

  watch: {
    showDialog: {
      immediate: true,
      handler (val) {
        this.canShowDialog = val
      }
    }
  },

  methods: {
    closeDialog () {
      this.$emit('close')
    },

    async sendForSignature () {
      try {
        const { module, method, route } = this.apiConfig || {}

        if (module && method && route) {
          if (this.selectedEmployee) {
            const formData = new FormData()
            formData.append('signatory_person_id', this.selectedEmployee)
            formData.append('signature_type', this.signatureType)
            formData.append('travel_document_id', this.documentId)

            if (!isEmpty(this.additionalObject)) {
              for (const key in this.additionalObject) {
                formData.append(key, this.additionalObject[key] ?? '')
              }
            }

            await api()[module][method](
              route,
              formData
            )
            this.$emit('close', true)
            this.$emit('refresh-instance', this.instance)
          }
        }
        else {
          this.pushNotification(this.$t('base.invalid_api_configuration_save'))
        }
      }
      catch (exception) {
        console.log(exception)
      }
    },

    // Helper method to push notifications
    pushNotification (message) {
      store.dispatch('base/notifications/push', message)
    }
  }
}
</script>

<style scoped lang="scss">
.universal-buttons {
  width: 100%;
  height: 40px !important;
}

.v-card-text {
  padding: 0 !important;
}

.v-card {
  border-radius: 16px;
}

.v-card-title {
  font-family: 'Satoshi', sans-serif;
  font-weight: 500;
  font-size: 24px;
  border-bottom: 1px solid #E5E7EB;
}

.container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
</style>
