<template>
  <div
    class="main-div"
  >
    <v-row
      style="margin: 0; padding: 0; border-radius: 12px;"
    >
<!--      First column-->
      <v-col
        class="first-column"
      >
        {{ $t('fleet/documents/prl_cargo.vehicle_movement_in_total_operation') }}
      </v-col>

      <!--      Regular operation column-->
      <v-col
        class="regular-operation-main-column"
      >
        <v-row
          style="margin: 0; padding: 0; height: 40px; background-color: #F3F4F6; border-bottom: 1px solid #E5E7EB"
        >
          <v-col
            style="margin: 0; padding: 0; align-content: center; text-align: center;"
            cols="10"
          >
            {{ $t('fleet/documents/prl_cargo.regularly') }}
          </v-col>
          <v-col
            class="d-flex justify-end align-middle"
            style="padding: 0 !important;"
            cols="2"
          >
            <v-menu
              v-model="openedTimePickers.regular_operation"
              transition="slide-y-transition"
              nudge-top="530"
              offset-y
              :disabled="!canEdit"
              min-width="auto"
              :close-on-content-click="false"
            >
              <template
                #activator="{ on, attrs }"
              >
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                  :disabled="!canEdit"
                  :style="{ cursor: !canEdit ? 'not-allowed' : 'pointer', pointerEvents: !canEdit ? 'unset' : '' }"
                >
                  mdi-clock
                </v-icon>
              </template>
              <datetime-picker
                v-if="openedTimePickers.regular_operation"
                v-model="formData.regular_operation"
                :field="{ subType: 'time', format: 'HH:mm' }"
                @close="openedTimePickers.regular_operation = false"
                @reset-date="formData.regular_operation = ''"
                @applied-date="sendData"
              />
            </v-menu>
          </v-col>
        </v-row>
        <v-row
          style="margin: 0; padding: 0; height: 40px; background-color: #F3F4F6; border-bottom: 1px solid #E5E7EB"
        >
          <v-col
            style="margin: 0; padding: 0; border-right: 1px solid #E5E7EB; align-content: center; text-align: center;"
          >
            {{ $t('fleet/documents/prl_cargo.hours') }}
          </v-col>
          <v-col
            style="margin: 0; padding: 0; align-content: center; text-align: center;"
          >
            {{ $t('fleet/documents/prl_cargo.minutes') }}
          </v-col>
        </v-row>

        <v-row
          style="margin: 0; padding: 0; height: 120px"
        >
          <v-col
            style="margin: 0; padding: 0; border-right: 1px solid #E5E7EB; align-content: center; text-align: center;"
          >
            {{ formData.regular_operation && typeof formData.regular_operation === 'string' ? formData.regular_operation.split(':')[0] : '' }}
          </v-col>
          <v-col
            style="margin: 0; padding: 0; align-content: center; text-align: center;"
          >
            {{ formData.regular_operation && typeof formData.regular_operation === 'string' ? formData.regular_operation.split(':')[1] : '' }}
          </v-col>
        </v-row>
      </v-col>

      <!--      Overtime operation column-->
      <v-col
        class="regular-operation-main-column"
      >
        <v-row
          style="margin: 0; padding: 0; height: 40px; background-color: #F3F4F6; border-bottom: 1px solid #E5E7EB"
        >
          <v-col
            style="margin: 0; padding: 0; align-content: center; text-align: center;"
            cols="10"
          >
            {{ $t('fleet/documents/prl_cargo.overtime') }}
          </v-col>
          <v-col
            class="d-flex justify-end"
            style="padding: 0 !important;"
            cols="2"
          >
            <v-menu
              v-model="openedTimePickers.overtime_operation"
              transition="slide-y-transition"
              nudge-top="530"
              offset-y
              :disabled="!canEdit"
              min-width="auto"
              :close-on-content-click="false"
            >
              <template
                #activator="{ on, attrs }"
              >
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                  :disabled="!canEdit"
                  :style="{ cursor: !canEdit ? 'not-allowed' : 'pointer', pointerEvents: !canEdit ? 'unset' : '' }"
                >
                  mdi-clock
                </v-icon>
              </template>
              <datetime-picker
                v-if="openedTimePickers.overtime_operation"
                v-model="formData.overtime_operation"
                :field="{ subType: 'time', format: 'HH:mm' }"
                @close="openedTimePickers.overtime_operation = false"
                @reset-date="formData.overtime_operation = ''"
                @applied-date="sendData"
              />
            </v-menu>
          </v-col>
        </v-row>
        <v-row
          style="margin: 0; padding: 0; height: 40px; background-color: #F3F4F6; border-bottom: 1px solid #E5E7EB"
        >
          <v-col
            style="margin: 0; padding: 0; border-right: 1px solid #E5E7EB; align-content: center; text-align: center;"
          >
            {{ $t('fleet/documents/prl_cargo.hours') }}
          </v-col>
          <v-col
            style="margin: 0; padding: 0; align-content: center; text-align: center;"
          >
            {{ $t('fleet/documents/prl_cargo.minutes') }}
          </v-col>
        </v-row>

        <v-row
          style="margin: 0; padding: 0; height: 120px"
        >
          <v-col
            style="margin: 0; padding: 0; border-right: 1px solid #E5E7EB; align-content: center; text-align: center;"
          >
            {{ formData.overtime_operation && typeof formData.overtime_operation === 'string' ? formData.overtime_operation.split(':')[0] : '' }}
          </v-col>
          <v-col
            style="margin: 0; padding: 0; align-content: center; text-align: center;"
          >
            {{ formData.overtime_operation && typeof formData.overtime_operation === 'string' ? formData.overtime_operation.split(':')[1] : '' }}
          </v-col>
        </v-row>
      </v-col>

      <!--      Total operation column-->
      <v-col
        class="regular-operation-main-column"
      >
        <v-row
          style="margin: 0; padding: 0; height: 40px; background-color: #F3F4F6; border-bottom: 1px solid #E5E7EB"
        >
          <v-col
            style="margin: 0; padding: 0; align-content: center; text-align: center;"
            cols="10"
          >
            {{ $t('fleet/documents/prl_cargo.total') }}
          </v-col>
          <v-col
            class="d-flex justify-end"
            style="padding: 0 !important;"
            cols="2"
          >
            <v-menu
              v-model="openedTimePickers.total_operation"
              transition="slide-y-transition"
              nudge-top="530"
              offset-y
              :disabled="!canEdit"
              min-width="auto"
              :close-on-content-click="false"
            >
              <template
                #activator="{ on, attrs }"
              >
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                  :disabled="!canEdit"
                  :style="{ cursor: !canEdit ? 'not-allowed' : 'pointer', pointerEvents: !canEdit ? 'unset' : '' }"
                >
                  mdi-clock
                </v-icon>
              </template>
              <datetime-picker
                v-if="openedTimePickers.total_operation"
                v-model="formData.total_operation"
                :field="{ subType: 'time', format: 'HH:mm' }"
                @close="openedTimePickers.total_operation = false"
                @reset-date="formData.total_operation = ''"
                @applied-date="sendData"
              />
            </v-menu>
          </v-col>
        </v-row>
        <v-row
          style="margin: 0; padding: 0; height: 40px; background-color: #F3F4F6; border-bottom: 1px solid #E5E7EB"
        >
          <v-col
            style="margin: 0; padding: 0; border-right: 1px solid #E5E7EB; align-content: center; text-align: center;"
          >
            {{ $t('fleet/documents/prl_cargo.hours') }}
          </v-col>
          <v-col
            style="margin: 0; padding: 0; align-content: center; text-align: center;"
          >
            {{ $t('fleet/documents/prl_cargo.minutes') }}
          </v-col>
        </v-row>

        <v-row
          style="margin: 0; padding: 0; height: 120px"
        >
          <v-col
            style="margin: 0; padding: 0; border-right: 1px solid #E5E7EB; align-content: center; text-align: center;"
          >
            {{ formData.total_operation && typeof formData.total_operation === 'string' ? formData.total_operation.split(':')[0] : '' }}
          </v-col>
          <v-col
            style="margin: 0; padding: 0; align-content: center; text-align: center;"
          >
            {{ formData.total_operation && typeof formData.total_operation === 'string' ? formData.total_operation.split(':')[1] : '' }}
          </v-col>
        </v-row>
      </v-col>

      <!--      Driver signature column-->
      <v-col
        class="driver-signature-main-column"
      >
        <v-row
          style="margin: 0; padding: 0; height: 40px; background-color: #F3F4F6; border-bottom: 1px solid #E5E7EB"
        >
          <v-col
            style="margin: 0; padding: 0; align-content: center; text-align: center;"
            cols="8"
          >
            {{ $t('fleet/documents/prl_cargo.drivers_signature') }}
          </v-col>
          <v-col
            v-if="showDriverSign"
            class="d-flex justify-end"
            style="margin: 0; padding: 0; align-items: center;"
            :style="{ cursor: !disableDriverSignButton ? 'not-allowed' : 'default' }"
            cols="4"
          >
            <universal-button
              type="save"
              :label="!disableDriverSignButton ? $t('fleet/documents/prl_cargo.signed') : $t('base.signed_it')"
              style="margin-right: 4px;"
              :prepend-icon="!disableDriverSignButton ? 'mdi-check' : 'mdi-draw'"
              :prependIconStyle="{ fontSize: '19px', marginRight: '4px' }"
              :disabled="!disableDriverSignButton"
              @click="openDriverSignatureDialog"
            />
          </v-col>
        </v-row>
        <v-row
          style="margin: 0; padding: 0; height: 40px; background-color: #F3F4F6; border-bottom: 1px solid #E5E7EB"
        >
          <v-col
            style="margin: 0; padding: 0; border-right: 1px solid #E5E7EB; align-content: center; text-align: center;"
          >
            {{ $t('fleet/documents/prl_cargo.signature_image') }}
          </v-col>
          <v-col
            style="margin: 0; padding: 0; align-content: center; text-align: center;"
          >
            {{ $t('fleet/documents/prl_cargo.signature_time') }}
          </v-col>
        </v-row>

        <v-row
          style="margin: 0; padding: 0; height: 120px"
        >
          <v-col
            style="margin: 0; padding: 0; border-right: 1px solid #E5E7EB; align-content: center; text-align: center;"
            cols="6"
          >
            <v-img
              :src="signatureData.movement_driver_signature_image ? signatureData.movement_driver_signature_image : undefined"
            />
          </v-col>
          <v-col
            style="margin: 0; padding: 0; align-content: center; text-align: center;"
            cols="6"
          >
            {{ signatureData.movement_driver_signed_at ? signatureData.movement_driver_signed_at : '' }}
          </v-col>
        </v-row>
      </v-col>

      <!--      Auditor signature column-->
      <v-col
        class="auditor-signature-main-column"
      >
        <v-row
          style="margin: 0; padding: 0; height: 40px; background-color: #F3F4F6; border-bottom: 1px solid #E5E7EB"
        >
          <v-col
            style="margin: 0; padding: 0; align-content: center; text-align: center;"
            cols="8"
          >
            {{ $t('fleet/documents/prl_cargo.settlement_signature') }}
          </v-col>
          <v-col
            v-if="showAuditorSign"
            class="d-flex justify-end"
            cols="4"
            style="margin: 0; padding: 0; align-items: center;"
            :style="{ cursor: !disableAuditorSignButton ? 'not-allowed' : 'default' }"
          >
            <universal-button
              type="save"
              :label="!disableAuditorSignButton ? $t('fleet/documents/prl_cargo.signed') : $t('base.signed_it')"
              style="margin-right: 4px;"
              :prepend-icon="!disableAuditorSignButton ? 'mdi-check' : 'mdi-draw'"
              :color="signButtonColor"
              :disabled="!disableAuditorSignButton"
              @click="openAuditorSignatureDialog"
            />
          </v-col>
          <v-col
            v-if="signatureData.canEdit"
            class="d-flex justify-end"
            cols="4"
            style="margin: 0; padding: 0; align-items: center;"
          >
            <universal-button
              type="save"
              :label="$t('fleet/documents/prl_cargo.send_for_signature')"
              style="margin-right: 4px;"
              :disabled="!!signatureData.movement_auditor_signature_image"
              prependIcon="mdi-send"
              :prependIconStyle="{ fontSize: '14px', marginRight: '4px' }"
              @click="openSendForSignature()"
            />
          </v-col>
        </v-row>
        <v-row
          style="margin: 0; padding: 0; height: 40px; background-color: #F3F4F6; border-bottom: 1px solid #E5E7EB"
        >
          <v-col
            style="margin: 0; padding: 0; border-right: 1px solid #E5E7EB; align-content: center; text-align: center;"
          >
            {{ $t('fleet/documents/prl_cargo.signature_image') }}
          </v-col>
          <v-col
            style="margin: 0; padding: 0; border-right: 1px solid #E5E7EB; align-content: center; text-align: center;"
          >
            {{ $t('fleet/documents/prl_cargo.signature_time') }}
          </v-col>
          <v-col
            style="margin: 0; padding: 0; align-content: center; text-align: center;"
          >
            {{ $t('fleet/documents/prl_cargo.signature_full_name') }}
          </v-col>
        </v-row>

        <v-row
          style="margin: 0; padding: 0; height: 120px"
        >
          <v-col
            style="margin: 0; padding: 0; border-right: 1px solid #E5E7EB; align-content: center; text-align: center;"
            cols="4"
          >
            <v-img
              :src="signatureData.movement_auditor_signature_image ? signatureData.movement_auditor_signature_image : undefined"
              contain
            />
          </v-col>
          <v-col
            style="margin: 0; padding: 0; border-right: 1px solid #E5E7EB; align-content: center; text-align: center;"
            cols="4"
          >
            {{ signatureData.movement_auditor_signed_at ? signatureData.movement_auditor_signed_at : '' }}
          </v-col>
          <v-col
            style="margin: 0; padding: 0; align-content: center; text-align: center;"
            cols="4"
          >
            {{ signatureData.movement_auditor_signature_name ? signatureData.movement_auditor_signature_name : '' }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <signature-dialog
      v-if="signatureDialogData.show"
      :show-dialog="signatureDialogData.show"
      :config="signatureDialogData.config"
      :fetch-signature="signatureDialogData.fetchSignature"
      :signature-id="signatureDialogData.signatureId"
      :signature-type="signatureDialogData.signatureType"
      @close="onCloseSignatureDialog"
    />

    <send-for-signature-dialog
      v-if="sendForSignatureDialogData.show"
      :show-dialog="sendForSignatureDialogData.show"
      :title="sendForSignatureDialogData.title"
      :document-id="sendForSignatureDialogData.documentId"
      :api-config="sendForSignatureDialogData.apiConfig"
      :signature-type="sendForSignatureDialogData.signatureType"
      @close="onCloseSendForSignatureDialog"
    />
  </div>
</template>

<script>
import DatetimePicker from '@/global/components/view-layouts/tabs-layout/components/fields/DatetimePicker.vue'
import { api } from '@/global/services/api'
import UniversalButton from '@/global/components/buttons/UniversalButton.vue'
import SignatureDialog from './SignatureDialog.vue'
import SendForSignatureDialog from './SendForSignatureDialog.vue'

export default {
  name: 'VehicleMovementSignatureTable',

  components: {
    SendForSignatureDialog,
    SignatureDialog,
    UniversalButton,
    DatetimePicker
  },

  props: {
    signButtonColor: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      formData: {},
      signatureData: {},
      openedTimePickers: {},
      signatureDialogData: {
        show: false,
        config: {},
        fetchSignature: true,
        signatureId: null,
        signatureType: null
      },
      sendForSignatureDialogData: {
        show: false,
        title: this.$t('fleet/documents/prl_cargo.send_for_signature_title'),
        apiConfig: {
          module: 'fleet',
          method: 'post',
          route: 'travel-documents/request-signature'
        },
        documentId: this.$route.params.id,
        signatureType: 'PRL_CARGO_AUDITOR_MOVEMENT'
      },
      disableDriverSignButton: true,
      disableAuditorSignButton: true,
      showAuditorSign: undefined,
      showDriverSign: undefined,
      canEdit: false
    }
  },

  async created () {
    await this.fetchVehicleMovementInfo()
  },

  methods: {
    async sendData () {
      try {
        const newFormData = new FormData()
        newFormData.append('_method', 'PATCH')

        for (const dataKey in this.formData) {
          newFormData.append(dataKey, this.formData[dataKey])
        }

        await api().fleet.post(`travel-documents/prl-cargo/movement-info/${this.$route.params.id}`, newFormData)
        await this.fetchVehicleMovementInfo()
      }
      catch (exception) {
        console.log(exception)
      }
    },

    openDriverSignatureDialog () {
      const config = {
        title: this.$t('fleet/documents/prl_cargo.sign_document'),
        confirmDialogTitle: this.$t('fleet.sign_document'),
        tabsContentInstance: this.tabsContentInstance,
        apiConfig: {
          get: {
            module: 'fleet',
            route: 'travel-documents/get-driver-company-from-user',
            method: 'get'
          },
          post: {
            module: 'fleet',
            route: 'travel-documents/signature',
            method: 'post'
          }
        },
        pinConfirmationApiConfig: {
          post: {
            module: 'fleet',
            route: 'travel-documents/check-pin',
            method: 'post'
          }
        }
      }
      this.$set(this.signatureDialogData, 'config', config)
      this.$set(this.signatureDialogData, 'fetchSignature', true)
      this.$set(this.signatureDialogData, 'signatureType', 'PRL_CARGO_DRIVER_MOVEMENT')
      this.$set(this.signatureDialogData, 'show', true)
    },

    openAuditorSignatureDialog () {
      const config = {
        title: this.$t('fleet/documents/prl_cargo.sign_document'),
        confirmDialogTitle: this.$t('fleet.sign_document'),
        tabsContentInstance: this.tabsContentInstance,
        apiConfig: {
          get: {
            module: 'fleet',
            route: 'travel-documents/get-driver-company-from-user',
            method: 'get'
          },
          post: {
            module: 'fleet',
            route: 'travel-documents/signature',
            method: 'post'
          }
        },
        pinConfirmationApiConfig: {
          post: {
            module: 'fleet',
            route: 'travel-documents/check-pin',
            method: 'post'
          }
        }
      }
      this.$set(this.signatureDialogData, 'config', config)
      this.$set(this.signatureDialogData, 'fetchSignature', true)
      this.$set(this.signatureDialogData, 'signatureType', 'PRL_CARGO_AUDITOR_MOVEMENT')
      this.$set(this.signatureDialogData, 'show', true)
    },

    async onCloseSignatureDialog (shouldRefreshData = false) {
      this.$set(this.signatureDialogData, 'show', false)
      if (shouldRefreshData) {
        await this.fetchVehicleMovementInfo()
      }
    },

    openSendForSignature () {
      this.$set(this.sendForSignatureDialogData, 'show', true)
    },

    async onCloseSendForSignatureDialog (shouldRefreshData = false) {
      this.$set(this.sendForSignatureDialogData, 'show', false)
      if (shouldRefreshData) {
        await this.fetchVehicleMovementInfo()
      }
    },

    async fetchVehicleMovementInfo () {
      try {
        const { data, meta } = await api().fleet.get(`travel-documents/prl-cargo/movement-info/${this.$route.params.id}`)

        const {
          regular_operation: regularOperation,
          overtime_operation: overtimeOperation,
          total_operation: totalOperation,
          movement_driver_signature_image: movementDriverSignatureImage,
          movement_driver_signed_at: movementDriverSignedAt,
          movement_auditor_signature_image: movementAuditorSignatureImage,
          movement_auditor_signed_at: movementAuditorSignedAt,
          movement_auditor_signature_name: movementAuditorSignatureName,
          movement_driver_can_sign: movementDriverCanSign,
          movement_auditor_can_sign: movementAuditorCanSign
        } = data

        this.$set(this.formData, 'regular_operation', regularOperation || '')
        this.$set(this.formData, 'overtime_operation', overtimeOperation || '')
        this.$set(this.formData, 'total_operation', totalOperation || '')

        this.$set(this.signatureData, 'movement_driver_signature_image', movementDriverSignatureImage || '')
        this.$set(this.signatureData, 'movement_driver_signed_at', movementDriverSignedAt || '')
        this.$set(this.signatureData, 'movement_auditor_signature_image', movementAuditorSignatureImage || '')
        this.$set(this.signatureData, 'movement_auditor_signed_at', movementAuditorSignedAt || '')
        this.$set(this.signatureData, 'movement_auditor_signature_name', movementAuditorSignatureName || '')
        this.$set(this.signatureData, 'movement_driver_can_sign', movementDriverCanSign || '')
        this.$set(this.signatureData, 'movement_auditor_can_sign', movementAuditorCanSign || '')
        this.$set(this.signatureData, 'canEdit', meta?.permissions?.canEdit || false)

        this.disableDriverSignButton = !movementDriverSignatureImage
        this.disableAuditorSignButton = !movementAuditorSignatureImage
        this.showAuditorSign = movementAuditorCanSign
        this.showDriverSign = movementDriverCanSign
        this.canEdit = meta?.permissions?.canEdit
      }
      catch (exception) {
        console.log(exception)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.main-div {
  display: flex;
  flex-direction: column;
  height: 200px;
  border: 1px solid #E5E7EB;
  border-radius: 12px;
  margin-top: 15px;
  overflow: auto;
}

.first-column {
  margin: 0;
  max-width: 130px;
  min-width: 130px;
  background-color: #F3F4F6;
  padding: 0;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  border-right: 1px solid #E5E7EB;
  align-content: center;
  text-align: center;
}

.regular-operation-main-column {
  margin: 0;
  padding: 0;
  border-right: 1px solid #E5E7EB;
  min-width: 208px;
  align-content: center;
  text-align: center;
}

.driver-signature-main-column {
  margin: 0;
  padding: 0;
  border-right: 1px solid #E5E7EB;
  min-width: 279px;
  align-content: center;
  text-align: center;
}

.auditor-signature-main-column {
  margin: 0;
  padding: 0;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  min-width: 503px;
  align-content: center;
  text-align: center;
}
</style>
