<template>
  <tabs-layout
    :config="config"
    :edit-id="this.$route.params.id"
    :create-mode="false"
    :redirect-view-name="'PrlCargo'"
    :first-tab-key="$route.query.tab"
    @tab-content-instance="instance => tabsContentInstance = instance"
    @main-table-instance="instance => tableInstance = instance"
  >
    <template
      #dynamic-content
    >
      <signature-dialog
        v-if="signatureDialogData.show"
        :show-dialog="signatureDialogData.show"
        :config="signatureDialogData.config"
        :fetch-signature="signatureDialogData.fetchSignature"
        :signature-id="signatureDialogData.signatureId"
        :signature-type="signatureDialogData.signatureType"
        :instance="signatureDialogData.instance"
        @close="closeSignatureDialog"
        @refresh-instance="refreshInstance"
      />

      <third-party-signature
        v-if="thirdPartySignatureDialogConfig.show"
        :show-dialog="thirdPartySignatureDialogConfig.show"
        :title="thirdPartySignatureDialogConfig.title"
        :api-config="thirdPartySignatureDialogConfig.apiConfig"
        :signature-type="thirdPartySignatureDialogConfig.signatureType"
        :document-id="$route.params.id"
        :additional-object="thirdPartySignatureDialogConfig.additionalObject"
        :instance="thirdPartySignatureDialogConfig.instance"
        @close="closeThirdPartySignatureDialog"
        @refresh-instance="refreshInstance"
      />

      <change-status-dialog
        v-if="changeStatusDialogConfig.show"
        :show-dialog="changeStatusDialogConfig.show"
        :title="changeStatusDialogConfig.title"
        :statuses="changeStatusDialogConfig.statuses"
        :current-status="changeStatusDialogConfig.current_status"
        :api-config="changeStatusDialogConfig.apiConfig"
        :instance="changeStatusDialogConfig.instance"
        :document-id="changeStatusDialogConfig.document_id"
        @close="closeChangeStatusDialog"
        @refresh-instance="refreshInstance"
      />

      <send-for-signature-dialog
        v-if="sendForSignatureDialogData.show"
        :show-dialog="sendForSignatureDialogData.show"
        :title="sendForSignatureDialogData.title"
        :document-id="sendForSignatureDialogData.documentId"
        :api-config="sendForSignatureDialogData.apiConfig"
        :signature-type="sendForSignatureDialogData.signatureType"
        :additional-object="sendForSignatureDialogData.additionalObject"
        :instance="sendForSignatureDialogData.instance"
        @close="onCloseSendForSignatureDialog"
        @refresh-instance="refreshInstance"
      />

      <information-dialog
        v-if="informationDialogData.show"
        :open-dialog="informationDialogData.show"
        :dialog-message="informationDialogData.message"
        :negative-button-text="informationDialogData.cancelText"
        :positive-button-text="informationDialogData.confirmText"
        :title="informationDialogData.title"
        :confirm-callback-function="informationDialogData.callback"
        @close="onCloseInformationDialog"
      />
    </template>

    <template
      #under-table-content
    >
      <vehicle-movement-signature-table
        :sign-button-color="$route.query.sign_button_color ? $route.query.sign_button_color : ''"
      />
    </template>
  </tabs-layout>

</template>

<script>
import SignatureDialog from '../../components/SignatureDialog.vue'
import tabsLayout from '@/global/components/view-layouts/tabs-layout/views/TabsLayout.vue'
import { api } from '@/global/services/api'
import ThirdPartySignature from '../../components/ThirdPartySignature.vue'
import ChangeStatusDialog from '../../components/ChangeStatus.vue'
import { currentDate, currentTime } from '@/global/services/helpers/dates'
import VehicleMovementSignatureTable from '../../components/VehicleMovementSignatureTable.vue'
import SendForSignatureDialog from '../../components/SendForSignatureDialog.vue'
import InformationDialog from '@/global/components/view-layouts/tabs-layout/components/dialogs/InformationDialog.vue'
import { isEmpty } from 'lodash'

export default {
  name: 'PrlCargoDetailsView',

  components: {
    InformationDialog,
    SendForSignatureDialog,
    VehicleMovementSignatureTable,
    ChangeStatusDialog,
    ThirdPartySignature,
    SignatureDialog,
    tabsLayout
  },

  data () {
    return {
      signatureDialogData: {
        show: false,
        config: {},
        fetchSignature: true,
        signatureId: null,
        signatureType: null,
        instance: null
      },
      thirdPartySignatureDialogConfig: {
        show: false,
        title: '',
        apiConfig: {},
        additionalObject: {},
        signatureType: null,
        instance: null
      },
      signatureDialogConfig: {},
      changeStatusDialogConfig: {
        show: false,
        apiConfig: {
          module: 'fleet',
          route: 'travel-documents/change-status',
          method: 'post'
        },
        getStatusesApiConfig: {
          module: 'fleet',
          route: 'travel-documents/statuses',
          method: 'get'
        },
        title: this.$t('fleet/documents/prl_cargo.select_new_status'),
        statuses: [],
        current_status: null,
        document_id: null,
        instance: null
      },
      sendForSignatureDialogData: {
        show: false,
        title: this.$t('fleet/documents/prl_cargo.send_for_signature_title'),
        apiConfig: {
          module: 'fleet',
          method: 'post',
          route: 'travel-documents/request-signature'
        },
        documentId: this.$route.params.id,
        additionalObject: {},
        signatureType: null,
        instance: null
      },
      informationDialogData: {
        show: false,
        message: '',
        cancelText: '',
        confirmText: '',
        title: '',
        callback: null
      },
      tabsContentInstance: null,
      tableInstance: null,
      vehicleRegistrationAutocompleteField: null,
      vehicleMovementPermissions: {},
      basicInfoPermissions: {},
      garagePermissions: {},
      fuelPermissions: {},
      tiresPermissions: {},
      plannedPermissions: {}
    }
  },

  computed: {
    config () {
      return {
        tabs: [
          // Basic info tab
          {
            title: this.$t('fleet/vehicle.basic_info'),
            apiConfig: {
              post: {
                module: 'fleet',
                route: `travel-documents/prl-cargo/basic-info/${this.$route.params.id}`,
                method: 'post'
              },
              get: {
                module: 'fleet',
                route: `travel-documents/prl-cargo/basic-info/${this.$route.params.id}`,
                method: 'get'
              }
            },
            fileKeys: [
              'drivers_signature'
            ],
            hiddenFormKeys: [
              'vehicle_id',
              'trailer_id',
              'crew_members'
            ],
            permissions: this.basicInfoPermissions,
            onDataReceived: (data) => {
              const { meta } = data || {}
              const { permissions } = meta || {}
              this.$set(this.basicInfoPermissions, 'canEdit', permissions?.canEdit !== undefined ? permissions.canEdit : true)
            },
            additionalSetup: async (event) => await this.callBasicInfoAdditionalSetup(event),
            additionalDataManipulation: (data) => this.handleAdditionalDataManipulation(data),
            tabContent: [
              // PRL data card
              {
                title: this.$t('fleet/vehicle.basic_info'),
                subTitle: this.$t('fleet/documents/prl_cargo.data_of_prl'),
                type: 'columns',
                showButtons: true,
                showBackButton: {
                  uiViewName: 'PrlCargo'
                },
                additional_buttons: [
                  {
                    type: 'base',
                    label: this.$t('fleet/documents/prl_cargo.view_pdf'),
                    prependIcon: 'mdi-file-pdf-box',
                    click: (event) => this.onBasicInfoViewPdf(event)
                  },
                  {
                    type: 'base',
                    label: this.$t('fleet/documents/prl_cargo.change_status'),
                    prependIcon: 'mdi-swap-horizontal',
                    click: (event) => this.onChangeStatus(event)
                  }
                ],
                fullWidth: true,
                useInitialAutocompletes: false,
                rows: [
                  {
                    type: 'fields',
                    columnsCount: 4,
                    fields: [
                      {
                        key: 'foreign_code',
                        label: this.$t('fleet/documents/prl_cargo.prl_no'),
                        row: 1,
                        column: 1,
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'date_time',
                        label: this.$t('fleet/documents/prl_cargo.creation_date'),
                        type: 'datetime-picker',
                        appendIcon: 'mdi-calendar-month',
                        format: 'DD.MM.YYYY. HH:mm',
                        placeholder: 'DD.MM.YYYY. HH:mm',
                        row: 1,
                        column: 2,
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'status_name',
                        label: this.$t('fleet/vehicle.status'),
                        row: 1,
                        column: 3,
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'transport_type',
                        label: this.$t('fleet/documents/prl_cargo.transport_type'),
                        creatable: true,
                        row: 1,
                        column: 4,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'company_name',
                        label: this.$t('fleet/documents/prl_cargo.company_name_and_address'),
                        creatable: true,
                        row: 2,
                        column: 1,
                        colSpan: 2,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'organizational_unit',
                        label: this.$t('fleet.organizational_unit'),
                        creatable: true,
                        row: 2,
                        column: 3,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'department',
                        label: this.$t('fleet.department'),
                        creatable: true,
                        row: 2,
                        column: 4,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'cost_location',
                        label: this.$t('fleet/documents/prl_cargo.cost_location'),
                        placeholder: this.$t('fleet/documents/prl_cargo.cost_location_placeholder'),
                        creatable: true,
                        row: 3,
                        column: 1,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'relation',
                        label: this.$t('fleet/documents/prl_cargo.relation_or_direction'),
                        placeholder: this.$t('fleet/documents/prl_cargo.relation_or_direction_placeholder'),
                        creatable: true,
                        row: 3,
                        column: 3,
                        editable: true,
                        visible: true
                      }
                    ]
                  }
                ]
              },
              // Crew members card
              {
                subTitle: this.$t('fleet/documents/prl_cargo.crew_members'),
                type: 'columns',
                fullWidth: true,
                rows: [
                  {
                    type: 'fields',
                    columnsCount: 4,
                    fields: [
                      {
                        key: 'driver_name',
                        label: this.$t('fleet/vehicle.driver'),
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'crew_member_1',
                        label: this.$t('fleet/documents/prl_cargo.other_crew_members').replace(':number', 1),
                        placeholder: this.$t('fleet/documents/prl_cargo.other_crew_members_placeholder').replace(':number', 1),
                        type: 'autocomplete',
                        autocomplete_options: {
                          module: 'people',
                          route: 'people/autocomplete',
                          fetchInitial: false,
                          returnValue: 'object'
                        },
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'crew_member_2',
                        label: this.$t('fleet/documents/prl_cargo.other_crew_members').replace(':number', 2),
                        placeholder: this.$t('fleet/documents/prl_cargo.other_crew_members_placeholder').replace(':number', 2),
                        type: 'autocomplete',
                        autocomplete_options: {
                          module: 'people',
                          route: 'people/autocomplete',
                          fetchInitial: false,
                          returnValue: 'object'
                        },
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'crew_member_3',
                        label: this.$t('fleet/documents/prl_cargo.other_crew_members').replace(':number', 3),
                        placeholder: this.$t('fleet/documents/prl_cargo.other_crew_members_placeholder').replace(':number', 1),
                        type: 'autocomplete',
                        autocomplete_options: {
                          module: 'people',
                          route: 'people/autocomplete',
                          fetchInitial: false,
                          returnValue: 'object'
                        },
                        creatable: true,
                        editable: true,
                        visible: true
                      }
                    ]
                  }
                ]
              },
              // Vehicle and trailer data card
              {
                subTitle: this.$t('fleet/documents/prl_cargo.vehicle_and_trailer_data'),
                type: 'columns',
                fullWidth: true,
                rows: [
                  {
                    type: 'fields',
                    columnsCount: 3,
                    fields: [
                      {
                        key: 'vehicle_brand',
                        label: this.$t('fleet/documents/prl_cargo.vehicle_brand'),
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'vehicle_registration',
                        label: this.$t('fleet/vehicle.registration'),
                        placeholder: this.$t('fleet/documents/prl_cargo.registration_number_placeholder'),
                        type: 'autocomplete',
                        onSelect: (event) => this.onVehicleRegistrationSelect(event),
                        autocomplete_options: {
                          module: 'fleet',
                          route: 'travel-documents/get-vehicle',
                          custom_display_value: () => {
                            return 'vehicle_registration'
                          },
                          additionalQueryParams: (event) => {
                            this.vehicleRegistrationAutocompleteField = event
                            return {
                              by: 'registration',
                              vehicle_type: 'vehicle',
                              document_type: 'PRL_CARGO'
                            }
                          },
                          returnValue: 'object',
                          form_value: 'vehicle_registration'
                        },
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'vehicle_garage_number',
                        label: this.$t('fleet/vehicle_attribute.garage_number'),
                        placeholder: this.$t('fleet/documents/prl_cargo.garage_number_placeholder'),
                        type: 'autocomplete',
                        onSelect: (event) => this.onVehicleGarageNumberSelect(event),
                        autocomplete_options: {
                          module: 'fleet',
                          route: 'travel-documents/get-vehicle',
                          custom_display_value: () => {
                            return 'vehicle_garage_number'
                          },
                          additionalQueryParams: {
                            by: 'garage_number',
                            vehicle_type: 'vehicle',
                            document_type: 'PRL_CARGO'
                          },
                          returnValue: 'object',
                          form_value: 'vehicle_garage_number'
                        },
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'trailer_brand',
                        label: this.$t('fleet/documents/prl_cargo.trailer_brand'),
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'trailer_registration',
                        label: this.$t('fleet/vehicle.registration'),
                        placeholder: this.$t('fleet/documents/prl_cargo.registration_number_placeholder'),
                        type: 'autocomplete',
                        onSelect: (event) => this.onTrailerRegistrationSelect(event),
                        autocomplete_options: {
                          module: 'fleet',
                          route: 'travel-documents/get-vehicle',
                          custom_display_value: () => {
                            return 'trailer_registration'
                          },
                          additionalQueryParams: {
                            by: 'registration',
                            vehicle_type: 'trailer',
                            document_type: 'PRL_CARGO'
                          },
                          returnValue: 'object',
                          form_value: 'trailer_registration'
                        },
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'trailer_garage_number',
                        label: this.$t('fleet/vehicle_attribute.garage_number'),
                        placeholder: this.$t('fleet/documents/prl_cargo.garage_number_placeholder'),
                        type: 'autocomplete',
                        onSelect: (event) => this.onTrailerGarageNumberSelect(event),
                        autocomplete_options: {
                          module: 'fleet',
                          route: 'travel-documents/get-vehicle',
                          custom_display_value: () => {
                            return 'trailer_garage_number'
                          },
                          additionalQueryParams: {
                            by: 'garage_number',
                            vehicle_type: 'trailer',
                            document_type: 'PRL_CARGO'
                          },
                          returnValue: 'object',
                          form_value: 'trailer_garage_number'
                        },
                        creatable: true,
                        editable: true,
                        visible: true
                      }
                    ]
                  }
                ]
              },
              // Signature card
              {
                type: 'columns',
                fullWidth: true,
                subTitle: this.$t('fleet/documents/prl_cargo.signature_title'),
                rows: [
                  {
                    type: 'fields',
                    fields: [
                      {
                        type: 'content-with-buttons',
                        label: this.$t('fleet/documents/prl_cargo.drivers_signature'),
                        contentStack: 'horizontal',
                        buttonsSide: 'right',
                        buttonsStack: 'vertical',
                        buttons: [
                          {
                            type: 'save',
                            label: (event) => {
                              const { modifiedFormData } = event || {}
                              return modifiedFormData && !!modifiedFormData.inspection_signature_image ? this.$t('fleet/documents/prl_cargo.signed') : this.$t('base.signed_it')
                            },
                            disabled: (event) => {
                              const { modifiedFormData } = event || {}
                              return modifiedFormData && (!!modifiedFormData.inspection_signature_image || !modifiedFormData.inspection_can_sign)
                            },
                            prependIcon: (event) => {
                              const { modifiedFormData } = event || {}
                              return modifiedFormData && !!modifiedFormData.inspection_signature_image ? 'mdi-check' : 'mdi-draw'
                            },
                            style: {
                              width: '96px'
                            },
                            prependIconStyle: {
                              color: '#FFFFFF !important',
                              marginRight: '4px',
                              fontSize: '19px'
                            },
                            click: (event) => this.openSignatureDialog(event, null, 'PRL_CARGO_INSPECTION', true)
                          }
                        ],
                        contentType: 'image',
                        contentStyle: {
                          height: '88px',
                          minWidth: '256px',
                          maxWidth: '256px',
                          border: '1px solid #E5E7EB',
                          borderRadius: '16px',
                          marginRight: '10px'
                        },
                        key: 'inspection_signature_image',
                        creatable: false,
                        editable: true,
                        visible: true
                      }
                    ]
                  }
                ]
              }
            ]
          },
          // Vehicle movement tab
          {
            title: this.$t('fleet/documents/prl_cargo.vehicle_movement'),
            tabContent: [
              {
                type: 'table',
                apiConfig: {
                  get: {
                    module: 'fleet',
                    route: `travel-documents/prl-cargo/${this.$route.params.id}/movement`,
                    method: 'get'
                  }
                },
                underTableContentStyle: {
                  height: '200px',
                  marginTop: '15px'
                },
                openDetail: {
                  id: this.$route.query.item_id ?? null,
                  config: this.vehicleMovementDetails
                },
                permissions: this.vehicleMovementPermissions,
                onDataReceived: (data) => {
                  const { meta } = data || {}
                  const { permissions } = meta || {}
                  this.$set(this.vehicleMovementPermissions, 'canEdit', permissions?.canEdit !== undefined ? permissions.canEdit : true)
                },
                rowClick: (event) => this.showVehicleMovementDetails(event),
                search_and_buttons_config: {
                  additional_buttons: [
                    {
                      type: 'base',
                      label: this.$t('fleet/documents/prl_cargo.get_data'),
                      prependIcon: 'mdi-download',
                      click: (event) => this.fillVehicleMovementTable(event)
                    },
                    {
                      type: 'base',
                      label: this.$t('fleet/documents/prl_cargo.view_pdf'),
                      prependIcon: 'mdi-file-pdf-box',
                      click: (event) => this.onVehicleMovementViewPdf(event)
                    },
                    {
                      type: 'base',
                      label: this.$t('fleet/documents/prl_cargo.change_status'),
                      prependIcon: 'mdi-swap-horizontal',
                      click: (event) => this.onChangeStatus(event)
                    }
                  ],
                  newClick: (event) => this.showVehicleMovementNewPage(event)
                },
                title_and_back_config: {
                  title: this.$t('fleet/documents/prl_cargo.vehicle_movement'),
                  showBackButton: {
                    uiViewName: 'PrlCargo'
                  }
                },
                filters_and_columns_visibility_config: {
                  showRefreshButton: false,
                  showFiltersButton: false,
                  showColumnsVisibilityButton: false
                },
                tableConfig: {
                  styles: {
                    useFixedHeaderWidth: true,
                    dataRowHeight: '64px',
                    headersHeight: '120px',
                    summaryHeight: '64px',
                    borderedCells: true
                  },
                  cellsConfig: {
                    rb: {
                      style: {
                        textAlign: 'center'
                      }
                    },
                    date: {
                      style: {
                        textAlign: 'center'
                      }
                    },
                    bill_of_lading_number: {
                      style: {
                        textAlign: 'center'
                      }
                    },
                    vehicle_movement: {
                      style: {
                        textAlign: 'center'
                      }
                    },
                    odometer: {
                      style: {
                        textAlign: 'center'
                      }
                    },
                    arrival_at: {
                      style: {
                        textAlign: 'center'
                      }
                    },
                    departure_at: {
                      style: {
                        textAlign: 'center'
                      }
                    },
                    loading_at: {
                      style: {
                        textAlign: 'center'
                      }
                    },
                    unloading_at: {
                      style: {
                        textAlign: 'center'
                      }
                    },
                    vehicle_condition_note: {
                      style: {
                        textAlign: 'center'
                      }
                    },
                    cargo_type: {
                      style: {
                        textAlign: 'center'
                      }
                    },
                    distance_with_cargo: {
                      style: {
                        textAlign: 'center'
                      }
                    },
                    distance_without_cargo: {
                      style: {
                        textAlign: 'center'
                      }
                    },
                    vehicle_loaded_weight: {
                      style: {
                        textAlign: 'center'
                      }
                    },
                    vehicle_unloaded_weight: {
                      style: {
                        textAlign: 'center'
                      }
                    },
                    vehicle_cargo: {
                      style: {
                        textAlign: 'center'
                      }
                    },
                    total_vehicle_movement: {
                      style: {
                        textAlign: 'center'
                      }
                    },
                    total_trailer_movement: {
                      style: {
                        textAlign: 'center'
                      }
                    },
                    recipient: {
                      style: {
                        textAlign: 'center'
                      }
                    }
                  },
                  headers: [
                    {
                      label: this.$t('fleet/documents/prl_cargo.rb'),
                      key: 'id',
                      style: {
                        width: '70px',
                        textAlign: 'center',
                        borderRight: '1px solid #D1D5DB',
                        borderBottom: '1px solid #D1D5DB'
                      },
                      always_visible: true
                    },
                    {
                      label: this.$t('fleet/documents/prl_cargo.date'),
                      key: 'date',
                      style: {
                        width: '120px',
                        textAlign: 'center',
                        borderRight: '1px solid #D1D5DB',
                        borderBottom: '1px solid #D1D5DB'
                      },
                      always_visible: true
                    },
                    {
                      label: this.$t('fleet/documents/prl_cargo.bill_of_lading_number'),
                      key: 'bill_of_lading_number',
                      style: {
                        width: '130px',
                        textAlign: 'center',
                        borderRight: '1px solid #D1D5DB',
                        borderBottom: '1px solid #D1D5DB'
                      },
                      always_visible: true
                    },
                    {
                      label: this.$t('fleet/documents/prl_cargo.vehicle_movement_table'),
                      key: 'vehicle_movement',
                      style: {
                        width: '296px',
                        textAlign: 'center',
                        borderRight: '1px solid #D1D5DB',
                        borderBottom: '1px solid #D1D5DB'
                      },
                      always_visible: true
                    },
                    {
                      label: this.$t('fleet/documents/prl_cargo.odometer'),
                      key: 'odometer',
                      style: {
                        width: '130px',
                        textAlign: 'center',
                        borderRight: '1px solid #D1D5DB',
                        borderBottom: '1px solid #D1D5DB'
                      },
                      always_visible: true
                    },
                    {
                      label: this.$t('fleet/documents/prl_cargo.arrival_at'),
                      key: 'arrival_at',
                      style: {
                        width: '130px',
                        textAlign: 'center',
                        borderRight: '1px solid #D1D5DB',
                        borderBottom: '1px solid #D1D5DB'
                      },
                      always_visible: true
                    },
                    {
                      label: this.$t('fleet/documents/prl_cargo.departure_at'),
                      key: 'departure_at',
                      style: {
                        width: '130px',
                        textAlign: 'center',
                        borderRight: '1px solid #D1D5DB',
                        borderBottom: '1px solid #D1D5DB'
                      },
                      always_visible: true
                    },
                    {
                      label: this.$t('fleet/documents/prl_cargo.vehicle_condition'),
                      style: {
                        width: '560px',
                        height: '60px',
                        borderRight: '1px solid #D1D5DB',
                        borderBottom: '1px solid #D1D5DB'
                      },
                      subHeaders: [
                        {
                          label: this.$t('fleet/documents/prl_cargo.loading_time'),
                          key: 'loading_at',
                          style: {
                            height: '60px',
                            borderRight: '1px solid #D1D5DB'
                          }
                        },
                        {
                          label: this.$t('fleet/documents/prl_cargo.unloading_time'),
                          key: 'unloading_at',
                          style: {
                            height: '60px',
                            borderRight: '1px solid #D1D5DB'
                          }
                        },
                        {
                          label: this.$t('fleet/documents/prl_cargo.vehicle_condition_note'),
                          key: 'vehicle_condition_note',
                          style: {
                            height: '60px',
                            borderRight: '1px solid #D1D5DB'
                          }
                        },
                        {
                          label: this.$t('fleet/documents/prl_cargo.cargo_type'),
                          key: 'cargo_type',
                          style: {
                            height: '60px',
                            borderRight: '1px solid #D1D5DB'
                          }
                        }
                      ],
                      always_visible: true
                    },
                    {
                      label: this.$t('fleet/documents/prl_cargo.crossed_km'),
                      style: {
                        width: '240px',
                        height: '60px',
                        borderRight: '1px solid #D1D5DB',
                        borderBottom: '1px solid #D1D5DB'
                      },
                      subHeaders: [
                        {
                          label: this.$t('fleet/documents/prl_cargo.with_cargo'),
                          key: 'distance_with_cargo',
                          style: {
                            height: '60px',
                            borderRight: '1px solid #D1D5DB'
                          }
                        },
                        {
                          label: this.$t('fleet/documents/prl_cargo.crossed_km'),
                          key: 'distance_without_cargo',
                          style: {
                            height: '60px',
                            borderRight: '1px solid #D1D5DB'
                          }
                        }
                      ],
                      always_visible: true
                    },
                    {
                      label: this.$t('fleet/documents/prl_cargo.transported_cargo'),
                      style: {
                        width: '360px',
                        height: '60px',
                        borderRight: '1px solid #D1D5DB',
                        borderBottom: '1px solid #D1D5DB'
                      },
                      subHeaders: [
                        {
                          label: this.$t('fleet/documents/prl_cargo.loaded'),
                          key: 'vehicle_loaded_weight',
                          style: {
                            height: '60px',
                            borderRight: '1px solid #D1D5DB'
                          }
                        },
                        {
                          label: this.$t('fleet/documents/prl_cargo.unloaded'),
                          key: 'vehicle_unloaded_weight',
                          style: {
                            height: '60px',
                            borderRight: '1px solid #D1D5DB'
                          }
                        },
                        {
                          label: this.$t('fleet/documents/prl_cargo.drive'),
                          key: 'vehicle_cargo',
                          style: {
                            height: '60px',
                            borderRight: '1px solid #D1D5DB'
                          }
                        }
                      ],
                      always_visible: true
                    },
                    {
                      label: this.$t('fleet/documents/prl_cargo.executed_km'),
                      style: {
                        width: '240px',
                        height: '60px',
                        borderRight: '1px solid #D1D5DB',
                        borderBottom: '1px solid #D1D5DB'
                      },
                      subHeaders: [
                        {
                          label: this.$t('fleet/documents/prl_cargo.motor_vehicle'),
                          key: 'total_vehicle_movement',
                          style: {
                            height: '60px',
                            borderRight: '1px solid #D1D5DB'
                          }
                        },
                        {
                          label: this.$t('fleet/documents/prl_cargo.connected_vehicle'),
                          key: 'total_trailer_movement',
                          style: {
                            height: '60px',
                            borderRight: '1px solid #D1D5DB'
                          }
                        }
                      ],
                      always_visible: true
                    },
                    {
                      style: {
                        width: '160px',
                        textAlign: 'center',
                        borderBottom: '1px solid #D1D5DB'
                      },
                      label: this.$t('fleet/documents/prl_cargo.recipient'),
                      key: 'recipient',
                      always_visible: true
                    }
                  ],
                  actionsConfig: [
                    {
                      label: this.$t('base.edit'),
                      icon: 'mdi-pencil-box',
                      click: (event) => this.onEditVehicleMovement(event)
                    },
                    {
                      label: this.$t('base.delete'),
                      icon: 'mdi-delete',
                      disabled: (event) => {
                        const { permissions } = event || {}

                        return permissions && !permissions.canEdit
                      },
                      click: (event) => this.onVehicleMovementRemove(event)
                    }
                  ],
                  summaryColumns: [
                    'label',
                    'odometer_difference',
                    'departure_arrival_difference',
                    'loading_difference',
                    'unloading_difference',
                    'vehicle_condition_note_difference',
                    'cargo_type_difference',
                    'distance_with_cargo_difference',
                    'distance_without_cargo_difference',
                    'loaded_difference',
                    'unloaded_difference',
                    'vehicle_cargo_difference',
                    'total_vehicle_distance_difference',
                    'total_trailer_distance_difference',
                    'recipient_difference',
                    'actions'
                  ],
                  summaryConfig: {
                    label: {
                      cols: 4
                    },
                    departure_arrival_difference: {
                      cols: 2
                    }
                  }
                }
              }
            ]
          },
          // Garage tab
          {
            title: this.$t('fleet/documents/prl_cargo.garage'),
            apiConfig: {
              post: {
                module: 'fleet',
                route: `travel-documents/prl-cargo/garage/${this.$route.params.id}`,
                method: 'post'
              },
              get: {
                module: 'fleet',
                route: `travel-documents/prl-cargo/garage/${this.$route.params.id}`,
                method: 'get'
              }
            },
            fileKeys: [
              'departure_signature',
              'arrival_signature'
            ],
            permissions: this.garagePermissions,
            onDataReceived: (data) => {
              const { meta } = data || {}
              const { permissions } = meta || {}
              this.$set(this.garagePermissions, 'canEdit', permissions?.canEdit !== undefined ? permissions.canEdit : true)
            },
            tabContent: [
              // Departure card
              {
                title: this.$t('fleet/documents/prl_cargo.garage'),
                subTitle: this.$t('fleet/documents/prl_cargo.departure'),
                type: 'columns',
                showButtons: true,
                showBackButton: {
                  uiViewName: 'PrlCargo'
                },
                fullWidth: true,
                insideCardButtons: {
                  position: 'right',
                  buttons: [
                    {
                      type: 'save',
                      label: (event) => {
                        const { modifiedFormData } = event || {}
                        return modifiedFormData && !!modifiedFormData.departure_signature_image ? this.$t('fleet/documents/prl_cargo.signed') : this.$t('base.signed_it')
                      },
                      visible: (event) => {
                        const { modifiedFormData } = event || {}
                        return modifiedFormData && !!modifiedFormData.departure_can_sign
                      },
                      disabled: (event) => {
                        const { modifiedFormData } = event || {}
                        return modifiedFormData && !!modifiedFormData.departure_signature_image
                      },
                      color: this.$route.query.departure_color ?? '',
                      prependIcon: (event) => {
                        const { modifiedFormData } = event || {}
                        return modifiedFormData && !!modifiedFormData.departure_signature_image ? 'mdi-check' : 'mdi-draw'
                      },
                      click: (event) => this.onDepartureSign(event, null, 'PRL_CARGO_DEPARTURE', true)
                    },
                    {
                      type: 'save',
                      label: (event) => {
                        const { modifiedFormData } = event || {}
                        return modifiedFormData && !!modifiedFormData.departure_signature_image ? this.$t('fleet/documents/prl_cargo.signed') : this.$t('fleet/documents/prl_cargo.send_for_signature')
                      },
                      visible: () => {
                        const { canEdit } = this.garagePermissions || {}
                        return canEdit
                      },
                      disabled: (event) => {
                        const { modifiedFormData } = event || {}
                        return modifiedFormData && !!modifiedFormData.departure_signature_image
                      },
                      prependIcon: (event) => {
                        const { modifiedFormData } = event || {}
                        return modifiedFormData && !!modifiedFormData.departure_signature_image ? 'mdi-check' : 'mdi-send'
                      },
                      prependIconStyle: {
                        color: '#FFFFFF !important',
                        marginRight: '4px',
                        fontSize: '19px'
                      },
                      click: (event) => this.onDepartureSendForSignature(event)
                    },
                    {
                      type: 'base',
                      label: (event) => {
                        const { modifiedFormData } = event || {}
                        return modifiedFormData && !!modifiedFormData.departure_signature_image ? this.$t('fleet/documents/prl_cargo.signed') : this.$t('fleet/documents/prl_cargo.third_party_signature')
                      },
                      visible: (event) => {
                        const { canEdit } = this.garagePermissions || {}
                        return canEdit
                      },
                      prependIcon: (event) => {
                        const { modifiedFormData } = event || {}
                        return modifiedFormData && !!modifiedFormData.departure_signature_image ? 'mdi-check' : 'mdi-draw'
                      },
                      disabled: (event) => {
                        const { modifiedFormData } = event || {}
                        return modifiedFormData && !!modifiedFormData.departure_signature_image
                      },
                      color: '#65AD45',
                      style: {
                        color: '#FFFFFF'
                      },
                      prependIconStyle: {
                        color: '#FFFFFF !important',
                        marginRight: '4px',
                        fontSize: '19px'
                      },
                      click: (event) => this.onDepartureThirdPartySignature(event)
                    }
                  ]
                },
                rows: [
                  {
                    type: 'fields',
                    columnsCount: 4,
                    fields: [
                      {
                        key: 'departure_date',
                        label: this.$t('fleet/documents/prl_cargo.date'),
                        type: 'datetime-picker',
                        subType: 'date',
                        appendIcon: 'mdi-calendar-month',
                        format: 'DD.MM.YYYY.',
                        placeholder: 'DD.MM.YYYY.',
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'departure_time',
                        label: this.$t('fleet/documents/prl_cargo.time'),
                        type: 'datetime-picker',
                        subType: 'time',
                        appendIcon: 'mdi-clock',
                        format: 'HH:mm',
                        placeholder: 'HH:mm',
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'departure_odometer',
                        label: this.$t('fleet/documents/prl_cargo.kilometer_status'),
                        field_type: 'number',
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        type: 'content-with-buttons',
                        key: 'departure_signature_image',
                        label: this.$t('fleet/documents/prl_cargo.goalkeeper_signature'),
                        contentStack: 'horizontal',
                        contentType: 'image',
                        contentStyle: {
                          height: '88px',
                          border: '1px solid #E5E7EB',
                          borderRadius: '16px'
                        },
                        creatable: false,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'departure_signed_at',
                        label: this.$t('fleet/documents/prl_cargo.signature_time_and_date'),
                        type: 'datetime-picker',
                        appendIcon: 'mdi-calendar-month',
                        format: 'DD.MM.YYYY HH:mm',
                        placeholder: 'DD.MM.YYYY HH:mm',
                        creatable: false,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'departure_signature_name',
                        label: this.$t('fleet/documents/prl_cargo.signature_full_name'),
                        creatable: false,
                        editable: false,
                        visible: true
                      }
                    ]
                  }
                ]
              },
              // Arrival card
              {
                subTitle: this.$t('fleet/documents/prl_cargo.arrival'),
                type: 'columns',
                fullWidth: true,
                insideCardButtons: {
                  position: 'right',
                  buttons: [
                    {
                      type: 'save',
                      label: (event) => {
                        const { modifiedFormData } = event || {}
                        return modifiedFormData && !!modifiedFormData.arrival_signature_image ? this.$t('fleet/documents/prl_cargo.signed') : this.$t('base.signed_it')
                      },
                      visible: (event) => {
                        const { modifiedFormData } = event || {}
                        return modifiedFormData && !!modifiedFormData.arrival_can_sign
                      },
                      disabled: (event) => {
                        const { modifiedFormData } = event || {}
                        return modifiedFormData && !!modifiedFormData.arrival_signature_image
                      },
                      color: this.$route.query.arrival_color ?? '',
                      prependIcon: (event) => {
                        const { modifiedFormData } = event || {}
                        return modifiedFormData && !!modifiedFormData.arrival_signature_image ? 'mdi-check' : 'mdi-draw'
                      },
                      click: (event) => this.onArrivalSign(event, null, 'PRL_CARGO_ARRIVAL', true)
                    },
                    {
                      type: 'save',
                      label: (event) => {
                        const { modifiedFormData } = event || {}
                        return modifiedFormData && !!modifiedFormData.arrival_signature_image ? this.$t('fleet/documents/prl_cargo.signed') : this.$t('fleet/documents/prl_cargo.send_for_signature')
                      },
                      visible: () => {
                        const { canEdit } = this.garagePermissions || {}
                        return canEdit
                      },
                      prependIcon: (event) => {
                        const { modifiedFormData } = event || {}
                        return modifiedFormData && !!modifiedFormData.arrival_signature_image ? 'mdi-check' : 'mdi-send'
                      },
                      disabled: (event) => {
                        const { modifiedFormData } = event || {}
                        return modifiedFormData && !!modifiedFormData.arrival_signature_image
                      },
                      prependIconStyle: {
                        color: '#FFFFFF !important',
                        marginRight: '4px',
                        fontSize: '19px'
                      },
                      click: (event) => this.onArrivalSendForSignature(event)
                    },
                    {
                      type: 'base',
                      label: (event) => {
                        const { modifiedFormData } = event || {}
                        return modifiedFormData && !!modifiedFormData.arrival_signature_image ? this.$t('fleet/documents/prl_cargo.signed') : this.$t('fleet/documents/prl_cargo.third_party_signature')
                      },
                      visible: (event) => {
                        const { canEdit } = this.garagePermissions || {}
                        return canEdit
                      },
                      prependIcon: (event) => {
                        const { modifiedFormData } = event || {}
                        return modifiedFormData && !!modifiedFormData.arrival_signature_image ? 'mdi-check' : 'mdi-draw'
                      },
                      disabled: (event) => {
                        const { modifiedFormData } = event || {}
                        return modifiedFormData && !!modifiedFormData.arrival_signature_image
                      },
                      color: '#65AD45',
                      style: {
                        color: '#FFFFFF'
                      },
                      prependIconStyle: {
                        color: '#FFFFFF !important',
                        marginRight: '4px',
                        fontSize: '19px'
                      },
                      click: (event) => this.onArrivalThirdPartySignature(event)
                    }
                  ]
                },
                rows: [
                  {
                    type: 'fields',
                    columnsCount: 4,
                    fields: [
                      {
                        key: 'arrival_date',
                        label: this.$t('fleet/documents/prl_cargo.date'),
                        type: 'datetime-picker',
                        subType: 'date',
                        appendIcon: 'mdi-calendar-month',
                        format: 'DD.MM.YYYY.',
                        placeholder: 'DD.MM.YYYY.',
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'arrival_time',
                        label: this.$t('fleet/documents/prl_cargo.time'),
                        type: 'datetime-picker',
                        subType: 'time',
                        appendIcon: 'mdi-clock',
                        format: 'HH:mm',
                        placeholder: 'HH:mm',
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'arrival_odometer',
                        label: this.$t('fleet/documents/prl_cargo.kilometer_status'),
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        type: 'content-with-buttons',
                        key: 'arrival_signature_image',
                        label: this.$t('fleet/documents/prl_cargo.goalkeeper_signature'),
                        contentStack: 'horizontal',
                        contentType: 'image',
                        contentStyle: {
                          height: '88px',
                          border: '1px solid #E5E7EB',
                          borderRadius: '16px'
                        },
                        creatable: false,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'arrival_signed_at',
                        label: this.$t('fleet/documents/prl_cargo.signature_time_and_date'),
                        type: 'datetime-picker',
                        appendIcon: 'mdi-calendar-month',
                        format: 'DD.MM.YYYY HH:mm',
                        placeholder: 'DD.MM.YYYY HH:mm',
                        creatable: false,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'arrival_signature_name',
                        label: this.$t('fleet/documents/prl_cargo.signature_full_name'),
                        creatable: false,
                        editable: false,
                        visible: true
                      }
                    ]
                  }
                ]
              }
            ]
          },
          // Fuel and lubricant tab
          {
            title: this.$t('fleet/documents/prl_cargo.fuel_and_lubricant'),
            tabContent: [
              {
                title: this.$t('fleet/documents/prl_cargo.fuel_and_lubricant'),
                type: 'table',
                rowClick: (event) => this.onShowFuelDetailsPage(event),
                apiConfig: {
                  get: {
                    module: 'fleet',
                    route: `travel-documents/prl-cargo/${this.$route.params.id}/fuel`,
                    method: 'get'
                  }
                },
                openDetail: {
                  id: this.$route.query.item_id ?? null,
                  config: this.fuelLubricantDetails
                },
                permissions: this.fuelPermissions,
                onDataReceived: (data) => {
                  const { meta } = data || {}
                  const { permissions } = meta || {}
                  this.$set(this.fuelPermissions, 'canEdit', permissions?.canEdit !== undefined ? permissions.canEdit : true)
                },
                search_and_buttons_config: {
                  additional_buttons: [
                    {
                      type: 'base',
                      label: this.$t('fleet/documents/prl_cargo.view_pdf'),
                      prependIcon: 'mdi-file-pdf-box',
                      click: (event) => this.onFuelViewPdf(event)
                    },
                    {
                      type: 'base',
                      label: this.$t('fleet/documents/prl_cargo.change_status'),
                      prependIcon: 'mdi-swap-horizontal',
                      click: (event) => this.onChangeStatus(event)
                    }
                  ],
                  newClick: (event) => this.onShowNewFuelPage(event)
                },
                title_and_back_config: {
                  title: this.$t('fleet/documents/prl_cargo.fuel_and_lubricant'),
                  showBackButton: {
                    uiViewName: 'PrlCargo'
                  }
                },
                filters_and_columns_visibility_config: {
                  showRefreshButton: false,
                  showFiltersButton: false,
                  showColumnsVisibilityButton: false
                },
                tableConfig: {
                  styles: {
                    useFixedHeaderWidth: true,
                    dataRowHeight: '64px',
                    headersHeight: '60px'
                  },
                  cellsConfig: {
                    location: {
                      type: 'object',
                      displayValue: 'name'
                    },
                    partner: {
                      type: 'object',
                      displayValue: 'name'
                    },
                    fuel_signature_image: {
                      type: 'image',
                      style: {
                        textAlign: 'center'
                      }
                    },
                    date: {
                      style: {
                        textAlign: 'center'
                      }
                    },
                    time: {
                      style: {
                        textAlign: 'center'
                      }
                    },
                    amount: {
                      style: {
                        textAlign: 'center'
                      }
                    },
                    account_number: {
                      style: {
                        textAlign: 'center'
                      }
                    },
                    fuel_signed_at: {
                      style: {
                        textAlign: 'center'
                      }
                    }
                  },
                  headers: [
                    {
                      label: this.$t('fleet/documents/prl_cargo.sr_no'),
                      key: 'id',
                      style: {
                        width: '80px'
                      },
                      always_visible: true
                    },
                    {
                      label: this.$t('fleet/documents/prl_cargo.date'),
                      key: 'date',
                      style: {
                        width: '130px',
                        textAlign: 'center'
                      },
                      always_visible: true
                    },
                    {
                      label: this.$t('fleet/documents/prl_cargo.time'),
                      key: 'time',
                      style: {
                        width: '130px',
                        textAlign: 'center'
                      },
                      always_visible: true
                    },
                    {
                      label: this.$t('fleet/documents/prl_cargo.place'),
                      key: 'location',
                      style: {
                        width: '160px'
                      },
                      always_visible: true
                    },
                    {
                      label: this.$t('fleet/documents/prl_cargo.issuer_of_fuel'),
                      key: 'partner',
                      style: {
                        width: '190px'
                      },
                      always_visible: true
                    },
                    {
                      label: this.$t('fleet/documents/prl_cargo.amount'),
                      key: 'amount',
                      style: {
                        width: '135px',
                        textAlign: 'center'
                      },
                      always_visible: true
                    },
                    {
                      label: this.$t('fleet/documents/prl_cargo.account_number'),
                      key: 'account_number',
                      style: {
                        width: '130px',
                        textAlign: 'center'
                      },
                      always_visible: true
                    },
                    {
                      label: this.$t('fleet/documents/prl_cargo.signature_of_the_issuer'),
                      key: 'fuel_signature_image',
                      style: {
                        width: '220px',
                        textAlign: 'center'
                      },
                      always_visible: true
                    },
                    {
                      label: this.$t('fleet/documents/prl_cargo.issuer_signature_time'),
                      key: 'fuel_signed_at',
                      style: {
                        width: '180px',
                        textAlign: 'center'
                      },
                      always_visible: true
                    },
                    {
                      label: this.$t('fleet/documents/prl_cargo.name_and_surname_of_the_signature'),
                      key: 'fuel_signature_name',
                      style: {
                        width: '180px'
                      },
                      always_visible: true
                    }
                  ],
                  actionsConfig: [
                    {
                      label: this.$t('base.edit'),
                      icon: 'mdi-pencil-box',
                      click: (event) => this.onShowFuelDetailsPage(event)
                    },
                    {
                      label: this.$t('fleet/documents/prl_cargo.send_for_signature'),
                      icon: 'mdi-send',
                      disabled: (event) => {
                        const { permissions } = event || {}

                        return permissions && !permissions.canEdit
                      },
                      click: (event) => this.onFuelSendForSignature(event)
                    },
                    {
                      label: this.$t('base.delete'),
                      icon: 'mdi-delete',
                      disabled: (event) => {
                        const { permissions } = event || {}

                        return permissions && !permissions.canEdit
                      },
                      click: (event) => this.onFuelDelete(event)
                    }
                  ]
                }
              }
            ]
          },
          // Mileage tires tab
          {
            title: this.$t('fleet/documents/prl_cargo.mileage_of_tires'),
            tabContent: [
              {
                title: this.$t('fleet/documents/prl_cargo.mileage_of_tires'),
                type: 'table',
                rowClick: (event) => this.onShowTireDetailsPage(event),
                apiConfig: {
                  get: {
                    module: 'fleet',
                    route: `travel-documents/prl-cargo/${this.$route.params.id}/tire`,
                    method: 'get'
                  }
                },
                permissions: this.tiresPermissions,
                onDataReceived: (data) => {
                  const { meta } = data || {}
                  const { permissions } = meta || {}
                  this.$set(this.tiresPermissions, 'canEdit', permissions?.canEdit !== undefined ? permissions.canEdit : true)
                },
                search_and_buttons_config: {
                  additional_buttons: [
                    {
                      type: 'base',
                      label: this.$t('fleet/documents/prl_cargo.view_pdf'),
                      prependIcon: 'mdi-file-pdf-box',
                      click: (event) => this.onTiresViewPdf(event)
                    },
                    {
                      type: 'base',
                      label: this.$t('fleet/documents/prl_cargo.change_status'),
                      prependIcon: 'mdi-swap-horizontal',
                      click: (event) => this.onChangeStatus(event)
                    }
                  ],
                  newClick: (event) => this.onShowNewTirePage(event)
                },
                title_and_back_config: {
                  title: this.$t('fleet/documents/prl_cargo.mileage_of_tires'),
                  showBackButton: {
                    uiViewName: 'PrlCargo'
                  }
                },
                filters_and_columns_visibility_config: {
                  showRefreshButton: false,
                  showFiltersButton: false,
                  showColumnsVisibilityButton: false
                },
                tableConfig: {
                  styles: {
                    useFixedHeaderWidth: true,
                    dataRowHeight: '64px',
                    headersHeight: '60px'
                  },
                  headers: [
                    {
                      label: this.$t('fleet/documents/prl_cargo.sr_no'),
                      key: 'id',
                      style: {
                        width: '80px'
                      },
                      always_visible: true
                    },
                    {
                      label: this.$t('fleet/documents/prl_cargo.brand'),
                      key: 'brand',
                      style: {
                        width: '180px'
                      },
                      always_visible: true
                    },
                    {
                      label: this.$t('fleet/documents/prl_cargo.factory_number'),
                      key: 'factory_number',
                      style: {
                        width: '180px'
                      },
                      always_visible: true
                    },
                    {
                      label: this.$t('fleet/documents/prl_cargo.dimension'),
                      key: 'dimension',
                      style: {
                        width: '180px'
                      },
                      always_visible: true
                    },
                    {
                      label: this.$t('fleet/documents/prl_cargo.km'),
                      key: 'distance',
                      style: {
                        width: '180px'
                      },
                      always_visible: true
                    },
                    {
                      label: this.$t('fleet/documents/prl_cargo.tires_condition'),
                      key: 'odometer',
                      style: {
                        width: '328px'
                      },
                      always_visible: true
                    },
                    {
                      label: this.$t('fleet/documents/prl_cargo.comment'),
                      key: 'comment',
                      style: {
                        width: '328px'
                      },
                      always_visible: true
                    }
                  ],
                  actionsConfig: [
                    {
                      label: this.$t('base.edit'),
                      icon: 'mdi-pencil-box',
                      click: (event) => this.onShowTireDetailsPage(event)
                    },
                    {
                      label: this.$t('base.delete'),
                      icon: 'mdi-delete',
                      disabled: (event) => {
                        const { permissions } = event || {}

                        return permissions && !permissions.canEdit
                      },
                      click: (event) => this.onTireDelete(event)
                    }
                  ]
                }
              }
            ]
          },
          // Planned completion tab
          {
            title: this.$t('fleet/documents/prl_cargo.planned_completion'),
            apiConfig: {
              post: {
                module: 'fleet',
                route: `travel-documents/prl-cargo/planned/${this.$route.params.id}`,
                method: 'post'
              },
              get: {
                module: 'fleet',
                route: `travel-documents/prl-cargo/planned/${this.$route.params.id}`,
                method: 'get'
              }
            },
            permissions: this.plannedPermissions,
            onDataReceived: (data) => {
              const { meta } = data || {}
              const { permissions } = meta || {}
              this.$set(this.plannedPermissions, 'canEdit', permissions?.canEdit !== undefined ? permissions.canEdit : true)
            },
            tabContent: [
              // Daily scheduled card
              {
                title: this.$t('fleet/documents/prl_cargo.planned_completion'),
                subTitle: this.$t('fleet/documents/prl_cargo.daily_scheduled'),
                type: 'columns',
                showButtons: true,
                showBackButton: {
                  uiViewName: 'PrlCargo'
                },
                additional_buttons: [
                  {
                    type: 'base',
                    label: this.$t('fleet/documents/prl_cargo.view_pdf'),
                    prependIcon: 'mdi-file-pdf-box',
                    click: (event) => this.onPlannedViewPdf(event)
                  },
                  {
                    type: 'base',
                    label: this.$t('fleet/documents/prl_cargo.change_status'),
                    prependIcon: 'mdi-swap-horizontal',
                    click: (event) => this.onChangeStatus(event)
                  }
                ],
                fullWidth: true,
                rows: [
                  {
                    type: 'fields',
                    columnsCount: 4,
                    fields: [
                      {
                        key: 'distance_planned',
                        label: this.$t('fleet/documents/prl_cargo.km'),
                        field_type: 'number',
                        creatable: true,
                        editable: true,
                        visible: true,
                        onInput: (value, instance) => {
                          const { modifiedFormData } = instance || {}
                          if (modifiedFormData.weight_planned && value) {
                            this.$set(modifiedFormData, 'planned_ratio', parseInt(modifiedFormData.weight_planned) / parseInt(value))
                          }
                        }
                      },
                      {
                        key: 'weight_planned',
                        label: this.$t('fleet/documents/prl_cargo.tons'),
                        field_type: 'number',
                        creatable: true,
                        editable: true,
                        visible: true,
                        onInput: (value, instance) => {
                          const { modifiedFormData } = instance || {}
                          if (modifiedFormData.distance_planned && value) {
                            this.$set(modifiedFormData, 'planned_ratio', parseInt(value) / parseInt(modifiedFormData.distance_planned))
                          }
                        }
                      },
                      {
                        key: 'planned_ratio',
                        label: this.$t('fleet/documents/prl_cargo.tons_km'),
                        field_type: 'number',
                        decimals: 2,
                        creatable: true,
                        editable: true,
                        visible: true
                      }
                    ]
                  }
                ]
              },
              // Done card
              {
                subTitle: this.$t('fleet/documents/prl_cargo.done'),
                type: 'columns',
                fullWidth: true,
                rows: [
                  {
                    type: 'fields',
                    columnsCount: 4,
                    fields: [
                      {
                        key: 'distance_completed',
                        label: this.$t('fleet/documents/prl_cargo.km'),
                        field_type: 'number',
                        creatable: true,
                        editable: true,
                        visible: true,
                        onInput: (value, instance) => {
                          const { modifiedFormData } = instance || {}
                          if (modifiedFormData.weight_completed && value) {
                            this.$set(modifiedFormData, 'completed_ratio', parseInt(modifiedFormData.weight_completed) / parseInt(value))
                          }
                        }
                      },
                      {
                        key: 'weight_completed',
                        label: this.$t('fleet/documents/prl_cargo.tons'),
                        field_type: 'number',
                        creatable: true,
                        editable: true,
                        visible: true,
                        onInput: (value, instance) => {
                          const { modifiedFormData } = instance || {}
                          if (modifiedFormData.distance_completed && value) {
                            this.$set(modifiedFormData, 'completed_ratio', parseInt(value) / parseInt(modifiedFormData.distance_completed))
                          }
                        }
                      },
                      {
                        key: 'completed_ratio',
                        field_type: 'number',
                        label: this.$t('fleet/documents/prl_cargo.tons_km'),
                        decimals: 2,
                        creatable: true,
                        editable: true,
                        visible: true
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ],
        breadcrumbs: {
          items: [
            {
              text: this.$t('fleet.travel_documents')
            },
            {
              text: this.$t('fleet.prl_cargo')
            },
            {
              text: this.$route.params.breadCrumbValue ?? this.$t('fleet/documents/prl_cargo.document_edit'),
              bolded: true
            }
          ]
        }
      }
    },

    vehicleMovementDetails () {
      return {
        title: this.$t('fleet/documents/prl_cargo.vehicle_movement_data'),
        apiConfig: {
          post: {
            module: 'fleet',
            route: (data, isCreate) => {
              if (isCreate) {
                return `travel-documents/prl-cargo/${this.$route.params.id}/movement`
              }
              else {
                return data && data.id ? `travel-documents/prl-cargo/${this.$route.params.id}/movement/${data.id}` : ''
              }
            },
            method: 'post'
          }
        },
        tabContent: [
          {
            subTitle: this.$t('fleet/documents/prl_cargo.vehicle_movement'),
            showButtons: true,
            type: 'columns',
            fullWidth: true,
            rows: [
              {
                type: 'fields',
                columnsCount: 3,
                fields: [
                  {
                    key: 'date',
                    label: this.$t('fleet/documents/prl_cargo.date'),
                    type: 'datetime-picker',
                    subType: 'date',
                    appendIcon: 'mdi-calendar-month',
                    format: 'DD.MM.YYYY.',
                    placeholder: 'DD.MM.YYYY.',
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'bill_of_lading_number',
                    label: this.$t('fleet/documents/prl_cargo.bill_of_lading_number'),
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'vehicle_movement',
                    label: this.$t('fleet/documents/prl_cargo.vehicle_movement'),
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'odometer',
                    label: this.$t('fleet/documents/prl_cargo.odometer'),
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'arrival_at',
                    label: this.$t('fleet/documents/prl_cargo.arrival_at'),
                    type: 'datetime-picker',
                    subType: 'time',
                    appendIcon: 'mdi-clock',
                    format: 'HH:mm',
                    placeholder: 'HH:mm',
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'departure_at',
                    label: this.$t('fleet/documents/prl_cargo.departure_at'),
                    type: 'datetime-picker',
                    subType: 'time',
                    appendIcon: 'mdi-clock',
                    format: 'HH:mm',
                    placeholder: 'HH:mm',
                    creatable: true,
                    editable: true,
                    visible: true
                  }
                ]
              }
            ]
          },
          {
            subTitle: this.$t('fleet/documents/prl_cargo.condition_of_the_vehicle'),
            type: 'columns',
            fullWidth: true,
            rows: [
              {
                type: 'fields',
                columnsCount: 4,
                fields: [
                  {
                    key: 'loading_at',
                    label: this.$t('fleet/documents/prl_cargo.loading_time'),
                    type: 'datetime-picker',
                    subType: 'time',
                    appendIcon: 'mdi-clock',
                    format: 'HH:mm',
                    placeholder: 'HH:mm',
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'unloading_at',
                    label: this.$t('fleet/documents/prl_cargo.unloading_time'),
                    type: 'datetime-picker',
                    subType: 'time',
                    appendIcon: 'mdi-clock',
                    format: 'HH:mm',
                    placeholder: 'HH:mm',
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'vehicle_condition_note',
                    label: this.$t('fleet/documents/prl_cargo.vehicle_condition_note'),
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'cargo_type',
                    label: this.$t('fleet/documents/prl_cargo.cargo_type'),
                    creatable: true,
                    editable: true,
                    visible: true
                  }
                ]
              }
            ]
          },
          {
            subTitle: this.$t('fleet/documents/prl_cargo.crossed_km'),
            type: 'columns',
            fullWidth: true,
            rows: [
              {
                type: 'fields',
                columnsCount: 4,
                fields: [
                  {
                    key: 'distance_with_cargo',
                    label: this.$t('fleet/documents/prl_cargo.with_cargo'),
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'distance_without_cargo',
                    label: this.$t('fleet/documents/prl_cargo.crossed_km'),
                    creatable: true,
                    editable: true,
                    visible: true
                  }
                ]
              }
            ]
          },
          {
            subTitle: this.$t('fleet/documents/prl_cargo.motor_vehicle_and_trailer_cargo_km'),
            type: 'columns',
            fullWidth: true,
            rows: [
              {
                type: 'fields',
                columnsCount: 3,
                fields: [
                  {
                    key: 'vehicle_loaded_weight',
                    label: this.$t('fleet/documents/prl_cargo.loaded_in_vehicle'),
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'vehicle_unloaded_weight',
                    label: this.$t('fleet/documents/prl_cargo.unloaded_from_vehicle'),
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'vehicle_cargo',
                    label: this.$t('fleet/documents/prl_cargo.drive_in_vehicle'),
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'trailer_loaded_weight',
                    label: this.$t('fleet/documents/prl_cargo.loaded_in_trailer'),
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'trailer_unloaded_weight',
                    label: this.$t('fleet/documents/prl_cargo.unloaded_from_trailer'),
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'trailer_cargo',
                    label: this.$t('fleet/documents/prl_cargo.drive_trailer'),
                    creatable: true,
                    editable: true,
                    visible: true
                  }
                ]
              }
            ]
          },
          {
            subTitle: this.$t('fleet/documents/prl_cargo.executed_km'),
            type: 'columns',
            fullWidth: true,
            rows: [
              {
                type: 'fields',
                columnsCount: 3,
                fields: [
                  {
                    key: 'total_vehicle_distance',
                    label: this.$t('fleet/documents/prl_cargo.motor_vehicle'),
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'total_trailer_distance',
                    label: this.$t('fleet/documents/prl_cargo.connected_vehicle'),
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'recipient',
                    label: this.$t('fleet/documents/prl_cargo.recipient'),
                    creatable: true,
                    editable: true,
                    visible: true
                  }
                ]
              }
            ]
          }
        ]
      }
    },

    fuelLubricantDetails () {
      return {
        title: this.$t('fleet/documents/prl_cargo.fuel_and_lubricant_data'),
        apiConfig: {
          post: {
            module: 'fleet',
            route: (data, isCreate) => {
              if (isCreate) {
                return `travel-documents/prl-cargo/${this.$route.params.id}/fuel`
              }
              else {
                return data && data.id ? `travel-documents/prl-cargo/${this.$route.params.id}/fuel/${data.id}` : ''
              }
            },
            method: 'post'
          }
        },
        fileKeys: [
          'signature_image'
        ],
        tabContent: [
          {
            type: 'columns',
            showButtons: true,
            showBackButton: {
              uiViewName: 'PrlCargo'
            },
            fullWidth: true,
            insideCardButtons: {
              position: 'right',
              buttons: [
                {
                  type: 'save',
                  label: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.fuel_signature_image ? this.$t('fleet/documents/prl_cargo.signed') : this.$t('base.signed_it')
                  },
                  visible: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.fuel_can_sign
                  },
                  disabled: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.fuel_signature_image
                  },
                  prependIcon: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.fuel_signature_image ? 'mdi-check' : 'mdi-draw'
                  },
                  color: this.$route.query.sign_button_color ?? '',
                  prependIconStyle: {
                    color: '#FFFFFF !important',
                    marginRight: '4px',
                    fontSize: '19px'
                  },
                  click: (event) => this.onFuelSign(event, null, 'PRL_CARGO_FUEL_ISSUER', true)
                },
                {
                  type: 'save',
                  label: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.fuel_signature_image ? this.$t('fleet/documents/prl_cargo.signed') : this.$t('fleet/documents/prl_cargo.send_for_signature')
                  },
                  visible: () => {
                    const { canEdit } = this.fuelPermissions || {}
                    return canEdit
                  },
                  prependIcon: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.fuel_signature_image ? 'mdi-check' : 'mdi-send'
                  },
                  disabled: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.fuel_signature_image
                  },
                  prependIconStyle: {
                    color: '#FFFFFF !important',
                    marginRight: '4px',
                    fontSize: '19px'
                  },
                  click: (event) => this.onFuelSendForSignature(event)
                },
                {
                  type: 'base',
                  label: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.fuel_signature_image ? this.$t('fleet/documents/prl_cargo.signed') : this.$t('fleet/documents/prl_cargo.third_party_signature')
                  },
                  visible: (event) => {
                    const { canEdit } = this.fuelPermissions || {}
                    return canEdit
                  },
                  prependIcon: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.fuel_signature_image ? 'mdi-check' : 'mdi-draw'
                  },
                  disabled: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.fuel_signature_image
                  },
                  color: '#65AD45',
                  style: {
                    color: '#FFFFFF'
                  },
                  prependIconStyle: {
                    color: '#FFFFFF !important',
                    marginRight: '4px',
                    fontSize: '19px'
                  },
                  click: (event) => this.onFuelThirdPartySignature(event)
                }
              ]
            },
            rows: [
              {
                type: 'fields',
                columnsCount: 4,
                fields: [
                  {
                    key: 'date',
                    label: this.$t('fleet/documents/prl_cargo.date'),
                    type: 'datetime-picker',
                    subType: 'date',
                    appendIcon: 'mdi-calendar-month',
                    format: 'DD.MM.YYYY.',
                    placeholder: 'DD.MM.YYYY.',
                    initialValue: (event) => {
                      const { createMode } = event?._props || {}
                      if (createMode) {
                        return currentDate('DD.MM.YYYY.')
                      }
                    },
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'time',
                    label: this.$t('fleet/documents/prl_cargo.time'),
                    type: 'datetime-picker',
                    subType: 'time',
                    appendIcon: 'mdi-calendar-month',
                    format: 'HH:mm',
                    placeholder: 'HH:mm',
                    initialValue: (event) => {
                      const { createMode } = event?._props || {}
                      if (createMode) {
                        return currentTime('HH:mm')
                      }
                    },
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'location',
                    label: this.$t('fleet/documents/prl_cargo.place'),
                    type: 'autocomplete',
                    autocomplete_options: {
                      module: 'fleet',
                      route: 'travel-documents/locations/autocomplete',
                      returnValue: 'object',
                      form_value: 'name'
                    },
                    keepInput: (input) => {
                      return {
                        value: input,
                        items: input ? [
                          {
                            name: input
                          }
                        ] : []
                      }
                    },
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'partner',
                    label: this.$t('fleet/documents/prl_cargo.issuer_of_fuel'),
                    type: 'autocomplete',
                    autocomplete_options: {
                      module: 'administration',
                      route: 'partners/autocomplete',
                      returnValue: 'object',
                      returnValueProps: ['id', 'name']
                    },
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'account_number',
                    label: this.$t('fleet/documents/prl_cargo.account_number'),
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'amount',
                    label: this.$t('fleet/documents/prl_cargo.bill_amount'),
                    field_type: 'number',
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'fuel_signature_name',
                    label: this.$t('fleet/documents/prl_cargo.issuer_name_and_surname'),
                    creatable: true,
                    editable: false,
                    visible: (event) => {
                      const { createMode } = event || {}

                      return !createMode
                    }
                  },
                  {
                    key: 'fuel_signed_at',
                    label: this.$t('fleet/documents/prl_cargo.issuer_signature_time'),
                    placeholder: 'DD.MM.YYYY HH:mm',
                    creatable: true,
                    editable: false,
                    visible: (event) => {
                      const { createMode } = event || {}

                      return !createMode
                    }
                  },
                  {
                    type: 'content-with-buttons',
                    key: 'fuel_signature_image',
                    label: this.$t('fleet/documents/prl_cargo.signature_of_the_issuer'),
                    contentStack: 'horizontal',
                    contentType: 'image',
                    contentStyle: {
                      height: '88px',
                      border: '1px solid #E5E7EB',
                      borderRadius: '16px'
                    },
                    creatable: true,
                    editable: false,
                    visible: (event) => {
                      const { createMode } = event || {}

                      return !createMode
                    }
                  }
                ]
              }
            ]
          },
          {
            title: this.$t('fleet/documents/prl_cargo.cost_information'),
            type: 'dynamic-columns',
            key: 'invoice_items',
            display_props: ['article', 'quantity', 'fuel_type'],
            useInitialAutocompletes: true,
            showInitialRows: 1,
            fullWidth: true,
            headers: [
              this.$t('fleet/documents/prl_cargo.article'),
              this.$t('fleet/documents/prl_cargo.amount'),
              this.$t('fleet/documents/prl_cargo.type_of_fuel_lubricant')
            ],
            fields: [
              {
                key: 'article',
                type: 'autocomplete',
                placeholder: this.$t('fleet/documents/prl_cargo.article_placeholder'),
                autocomplete_options: {
                  module: 'fleet',
                  route: 'travel-documents/fuel-article/autocomplete',
                  returnValue: 'object',
                  form_value: 'article.name'
                },
                keepInput: (input) => {
                  return {
                    value: input,
                    items: input ? [
                      {
                        name: input
                      }
                    ] : []
                  }
                },
                creatable: true,
                editable: true,
                visible: true
              },
              {
                key: 'quantity',
                placeholder: this.$t('fleet/documents/prl_cargo.amount_placeholder'),
                field_type: 'number',
                creatable: true,
                editable: true,
                visible: true
              },
              {
                key: 'fuel_type',
                type: 'autocomplete',
                placeholder: this.$t('fleet/documents/prl_cargo.fuel_type_placeholder'),
                label: this.$t('base/contact_type.type'),
                autocomplete_options: {
                  module: 'fleet',
                  route: 'travel-documents/fuel-item-type/autocomplete',
                  returnValue: 'object'
                },
                creatable: true,
                editable: true,
                visible: true
              }
            ]
          }
        ]
      }
    },

    tiresDetails () {
      return {
        title: this.$t('fleet/documents/prl_cargo.mileage_of_tires'),
        apiConfig: {
          post: {
            module: 'fleet',
            route: (data, isCreate) => {
              if (isCreate) {
                return `travel-documents/prl-cargo/${this.$route.params.id}/tire`
              }
              else {
                return data && data.id ? `travel-documents/prl-cargo/${this.$route.params.id}/tire/${data.id}` : ''
              }
            },
            method: 'post'
          }
        },
        fileKeys: [
          'signature_image'
        ],
        tabContent: [
          {
            type: 'columns',
            showButtons: true,
            showBackButton: {
              uiViewName: 'PrlCargo'
            },
            fullWidth: true,
            rows: [
              {
                type: 'fields',
                columnsCount: 3,
                fields: [
                  {
                    key: 'brand',
                    label: this.$t('fleet/documents/prl_cargo.brand'),
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'factory_number',
                    label: this.$t('fleet/documents/prl_cargo.factory_number'),
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'dimension',
                    label: this.$t('fleet/documents/prl_cargo.dimension'),
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'distance',
                    label: this.$t('fleet/documents/prl_cargo.km'),
                    field_type: 'number',
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'odometer',
                    label: this.$t('fleet/documents/prl_cargo.tires_condition'),
                    field_type: 'number',
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'comment',
                    label: this.$t('fleet/documents/prl_cargo.comment'),
                    creatable: true,
                    editable: true,
                    visible: true
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },

  methods: {
    onCloseInformationDialog () {
      this.$set(this.informationDialogData, 'show', false)
    },

    async onVehicleRegistrationSelect (event) {
      const { formData, instance, selectedItem, searchInput } = event || {}

      if (!searchInput && !selectedItem) {
        this.$set(formData, 'transport_type', '')
        this.$set(formData, 'organizational_unit', '')
        this.$set(formData, 'department', '')
        this.$set(formData, 'vehicle_brand', '')
        this.$set(formData, 'vehicle_garage_number', '')
      }
      else if (formData && selectedItem) {
        formData.transport_type = selectedItem.transport_type || ''
        formData.organizational_unit = selectedItem.organizational_unit ?? ''
        formData.department = selectedItem.department || ''
        formData.vehicle_id = selectedItem.id || null
        formData.vehicle_brand = selectedItem.vehicle_brand || ''

        // We need to call an autocomplete api to fill garage number items because we need to display garage number automatically given from vehicle registration selected value
        if (selectedItem.vehicle_garage_number) {
          const { data: garageNumberData, has_more: hasMore = false } = await api().fleet.get('travel-documents/get-vehicle', {
            query: selectedItem.vehicle_garage_number.toString(),
            by: 'garage_number',
            vehicle_type: 'vehicle',
            document_type: 'PRL_CARGO'
          })

          if (instance && instance.initialAutocompletes) {
            this.$set(instance.initialAutocompletes, 'vehicle_garage_number', {
              data: garageNumberData,
              hasMore: hasMore
            })

            this.$set(formData, 'vehicle_garage_number', selectedItem.vehicle_garage_number || '')
          }
        }
      }
    },

    async onCloseSendForSignatureDialog () {
      this.$set(this.sendForSignatureDialogData, 'show', false)
    },

    async refreshInstance (instance) {
      const { fetchData, fetchTabData, detailsDataIndex, detailsConfig, showTableDetailsPage, showTableDetailsPageFromOutside } = instance || {}

      if (this.$route.query.item_id && showTableDetailsPageFromOutside && fetchData) {
        await fetchData()
        showTableDetailsPageFromOutside(this.$route.query.item_id, this.fuelLubricantDetails)
      }
      else if (fetchData && detailsDataIndex !== undefined && !isEmpty(detailsConfig) && showTableDetailsPage) {
        await fetchData()
        showTableDetailsPage(detailsDataIndex, detailsConfig)
      }
      else if (fetchTabData) {
        await fetchTabData()
      }
      else if (fetchData) {
        await fetchData()
      }
    },

    async onVehicleGarageNumberSelect (event) {
      const { formData, instance, selectedItem, searchInput } = event || {}

      if (!searchInput && !selectedItem) {
        this.$set(formData, 'transport_type', '')
        this.$set(formData, 'organizational_unit', '')
        this.$set(formData, 'department', '')
        this.$set(formData, 'vehicle_brand', '')
        this.$set(formData, 'vehicle_registration', '')
      }
      else if (formData && selectedItem) {
        formData.transport_type = selectedItem.transport_type || ''
        formData.organizational_unit = selectedItem.organizational_unit ?? ''
        formData.department = selectedItem.department || ''
        formData.vehicle_id = selectedItem.id || null
        formData.vehicle_brand = selectedItem.vehicle_brand || ''

        // We need to call an autocomplete api to fill garage number items because we need to display garage number automatically given from vehicle registration selected value
        if (selectedItem.vehicle_registration) {
          const { data: garageNumberData, has_more: hasMore = false } = await api().fleet.get('travel-documents/get-vehicle', {
            query: selectedItem.vehicle_registration.toString(),
            by: 'registration',
            vehicle_type: 'vehicle',
            document_type: 'PRL_CARGO'
          })

          if (instance && instance.initialAutocompletes) {
            this.$set(instance.initialAutocompletes, 'vehicle_registration', {
              data: garageNumberData,
              hasMore: hasMore
            })

            this.$set(formData, 'vehicle_registration', selectedItem.vehicle_registration || '')
          }
        }
      }
    },

    async onTrailerRegistrationSelect (event) {
      const { formData, selectedItem, instance, searchInput } = event || {}

      if (!searchInput && !selectedItem) {
        this.$set(formData, 'trailer_brand', '')
        this.$set(formData, 'trailer_garage_number', '')
        this.$set(formData, 'trailer_id', null)
      }
      else if (formData && selectedItem) {
        formData.trailer_brand = selectedItem.trailer_brand || ''
        formData.trailer_id = selectedItem.id || null

        if (selectedItem.trailer_garage_number) {
          const { data: garageNumberData, has_more: hasMore = false } = await api().fleet.get('travel-documents/get-vehicle', {
            query: selectedItem.trailer_garage_number.toString(),
            by: 'garage_number',
            vehicle_type: 'trailer',
            document_type: 'PRL_CARGO'
          })

          if (instance && instance.initialAutocompletes) {
            this.$set(instance.initialAutocompletes, 'trailer_garage_number', {
              data: garageNumberData,
              hasMore: hasMore
            })

            this.$set(formData, 'trailer_garage_number', selectedItem.trailer_garage_number || '')
          }
        }
      }
    },

    async onTrailerGarageNumberSelect (event) {
      const { formData, selectedItem, instance, searchInput } = event || {}

      if (!searchInput && !selectedItem) {
        this.$set(formData, 'trailer_brand', '')
        this.$set(formData, 'trailer_registration', '')
        this.$set(formData, 'trailer_id', null)
      }
      else if (formData && selectedItem) {
        formData.trailer_brand = selectedItem.trailer_brand || ''
        formData.trailer_id = selectedItem.id || null

        if (selectedItem.trailer_registration) {
          const { data: registrationData, has_more: hasMore = false } = await api().fleet.get('travel-documents/get-vehicle', {
            query: selectedItem.trailer_registration.toString(),
            by: 'registration',
            vehicle_type: 'trailer',
            document_type: 'PRL_CARGO'
          })

          if (instance && instance.initialAutocompletes) {
            this.$set(instance.initialAutocompletes, 'trailer_registration', {
              data: registrationData,
              hasMore: hasMore
            })

            this.$set(formData, 'trailer_registration', selectedItem.trailer_registration || '')
          }
        }
      }
    },

    // Show vehicle movement row details page
    showVehicleMovementDetails (event) {
      const { index, instance } = event
      if (instance && instance.showTableDetailsPage && typeof instance.showTableDetailsPage === 'function') {
        instance.showTableDetailsPage(index, this.vehicleMovementDetails)
      }
    },

    // Show vehicle movement new page
    showVehicleMovementNewPage (event) {
      if (event && event.showTableNewPage && typeof event.showTableNewPage === 'function') {
        event.showTableNewPage(this.vehicleMovementDetails)
      }
    },

    // Show fuel and lubricant row details page
    onShowFuelDetailsPage (event) {
      const { index, instance } = event

      if (instance && instance.showTableDetailsPage && typeof instance.showTableDetailsPage === 'function') {
        instance.showTableDetailsPage(index, this.fuelLubricantDetails)
      }
    },

    // Show fuel and lubricant new page
    onShowNewFuelPage (event) {
      if (event && event.showTableNewPage && typeof event.showTableNewPage === 'function') {
        event.showTableNewPage(this.fuelLubricantDetails)
      }
    },

    // Show tires row details page
    onShowTireDetailsPage (event) {
      const { index, instance } = event

      if (instance && instance.showTableDetailsPage && typeof instance.showTableDetailsPage === 'function') {
        instance.showTableDetailsPage(index, this.tiresDetails)
      }
    },

    // Show tires new page
    onShowNewTirePage (event) {
      if (event && event.showTableNewPage && typeof event.showTableNewPage === 'function') {
        event.showTableNewPage(this.tiresDetails)
      }
    },

    // Fuel and lubricant view PDF
    onFuelViewPdf (event) {
    },

    // Tires view PDF
    onTiresViewPdf (event) {
    },

    onFuelDelete (event) {
      try {
        this.$set(this.informationDialogData, 'message', this.$t('base.delete_data_popup_message'))
        this.$set(this.informationDialogData, 'cancelText', this.$t('base.cancel'))
        this.$set(this.informationDialogData, 'confirmText', this.$t('base.confirm'))
        this.$set(this.informationDialogData, 'title', this.$t('base.delete_data_popup_title'))
        this.$set(this.informationDialogData, 'callback', async () => await this.onFuelDeleteCallback(event))
        this.$set(this.informationDialogData, 'show', true)
      }
      catch (exception) {
        console.log(exception)
      }
    },

    // Delete fuel row
    async onFuelDeleteCallback (event) {
      try {
        const { id } = event?.data || {}
        const { fetchData } = this.tableInstance || {}
        if (id) {
          await api().fleet.delete(`travel-documents/prl-cargo/${this.$route.params.id}/fuel/${id}`)
          this.$set(this.informationDialogData, 'show', false)

          if (fetchData && typeof fetchData === 'function') {
            fetchData()
          }
        }
      }
      catch (exception) {
        this.$set(this.informationDialogData, 'show', false)
        console.log(exception)
      }
    },

    async onTireDelete (event) {
      try {
        this.$set(this.informationDialogData, 'message', this.$t('base.delete_data_popup_message'))
        this.$set(this.informationDialogData, 'cancelText', this.$t('base.cancel'))
        this.$set(this.informationDialogData, 'confirmText', this.$t('base.confirm'))
        this.$set(this.informationDialogData, 'title', this.$t('base.delete_data_popup_title'))
        this.$set(this.informationDialogData, 'callback', async () => await this.onTireDeleteCallback(event))
        this.$set(this.informationDialogData, 'show', true)
      }
      catch (exception) {
        console.log(exception)
      }
    },

    // Delete tire row
    async onTireDeleteCallback (event) {
      try {
        const { id } = event?.data || {}
        const { fetchData } = this.tableInstance || {}
        if (id) {
          await api().fleet.delete(`travel-documents/prl-cargo/${this.$route.params.id}/tire/${id}`)

          this.$set(this.informationDialogData, 'show', false)
          if (fetchData && typeof fetchData === 'function') {
            fetchData()
          }
        }
      }
      catch (exception) {
        this.$set(this.informationDialogData, 'show', false)
        console.log(exception)
      }
    },

    // Send for signature fuel
    onFuelSendForSignature (event) {
      if (event && event.formData) {
        this.$set(this.sendForSignatureDialogData, 'additionalObject', {
          fuel_item_id: event?.formData?.id
        })
        this.$set(this.sendForSignatureDialogData, 'instance', event?.instance?.$parent?.$parent?.$parent)
      }
      else if (event && event.data) {
        this.$set(this.sendForSignatureDialogData, 'additionalObject', {
          fuel_item_id: event?.data?.id
        })
        this.$set(this.sendForSignatureDialogData, 'instance', event?.instance)
      }

      this.$set(this.sendForSignatureDialogData, 'signatureType', 'PRL_CARGO_FUEL_ISSUER')
      this.$set(this.sendForSignatureDialogData, 'show', true)
    },

    onFuelThirdPartySignature (event) {
      const { id } = event?.formData || {}
      this.$set(this.thirdPartySignatureDialogConfig, 'title', this.$t('fleet/documents/prl_cargo.add_signatory_information'))
      this.$set(this.thirdPartySignatureDialogConfig, 'signatureType', 'PRL_CARGO_FUEL_ISSUER')
      this.$set(this.thirdPartySignatureDialogConfig, 'apiConfig', {
        module: 'fleet',
        method: 'post',
        route: 'travel-documents/third-party-signature'
      })
      if (id) {
        this.$set(this.thirdPartySignatureDialogConfig, 'additionalObject', {
          fuel_item_id: id
        })
      }
      this.$set(this.thirdPartySignatureDialogConfig, 'instance', event?.instance?.$parent?.$parent?.$parent)
      this.$set(this.thirdPartySignatureDialogConfig, 'show', true)
    },

    // Remove vehicle movement row
    async onVehicleMovementRemove (event) {
      try {
        this.$set(this.informationDialogData, 'message', this.$t('fleet/documents/prl_cargo.delete_vehicle_movement_message'))
        this.$set(this.informationDialogData, 'cancelText', this.$t('base.cancel'))
        this.$set(this.informationDialogData, 'confirmText', this.$t('base.confirm'))
        this.$set(this.informationDialogData, 'title', this.$t('fleet/documents/prl_cargo.delete_vehicle_movement_title'))
        this.$set(this.informationDialogData, 'callback', async () => await this.onVehicleMovementRemoveCallback(event))
        this.$set(this.informationDialogData, 'show', true)
      }
      catch (exception) {
        console.log(exception)
      }
    },

    async onVehicleMovementRemoveCallback (event) {
      try {
        const { id } = event?.data || {}
        const { fetchData } = this.tableInstance || {}

        if (id) {
          await api().fleet.delete(`travel-documents/prl-cargo/${this.$route.params.id}/movement/${id}`)

          this.$set(this.informationDialogData, 'show', false)
          if (fetchData && typeof fetchData === 'function') {
            fetchData()
          }
        }
      }
      catch (exception) {
        this.$set(this.informationDialogData, 'show', false)
        console.log(exception)
      }
    },
    // Show vehicle movement row details page
    onEditVehicleMovement (event) {
      const { index, instance } = event || {}

      if (instance && instance.showTableDetailsPage && typeof instance.showTableDetailsPage === 'function') {
        instance.showTableDetailsPage(index, this.vehicleMovementDetails)
      }
    },

    // Fill vehicle movement table
    async fillVehicleMovementTable (event) {
      try {
        const { tableData } = event || {}

        if (tableData && ((tableData.data && tableData.data.length) || tableData.length)) {
          this.$set(this.informationDialogData, 'message', this.$t('fleet/documents/prl_cargo.overwriting_existing_data_message'))
          this.$set(this.informationDialogData, 'cancelText', this.$t('base.cancel'))
          this.$set(this.informationDialogData, 'confirmText', this.$t('base.confirm'))
          this.$set(this.informationDialogData, 'title', this.$t('base.warning'))
          this.$set(this.informationDialogData, 'callback', async () => await this.fillVehicleMovementTableCallback(event))
          this.$set(this.informationDialogData, 'show', true)
        }
        else {
          await this.fillVehicleMovementTableCallback(event)
        }
      }
      catch (exception) {
        console.log(exception)
      }
    },

    // Fill vehicle movement table callback
    async fillVehicleMovementTableCallback (event) {
      try {
        const { fetchData } = event || {}

        await api().fleet.get(`travel-documents/prl-cargo/${this.$route.params.id}/movement/from-trips`)

        this.$set(this.informationDialogData, 'show', false)
        if (fetchData && typeof fetchData === 'function') {
          event.fetchTableDataFromOutside = true
          await fetchData()
        }
      }
      catch (exception) {
        console.log(exception)
      }
    },

    async callBasicInfoAdditionalSetup (event) {
      const { initialAutocompletes, formDataForSend } = event || {}

      const { data: peopleData, has_more: hasMore = false } = await api().people.get('people/autocomplete', {})
      if (peopleData && peopleData.length && initialAutocompletes) {
        this.$set(initialAutocompletes, 'crew_member_1', {
          data: peopleData,
          hasMore: hasMore
        })

        this.$set(initialAutocompletes, 'crew_member_2', {
          data: peopleData,
          hasMore: hasMore
        })

        this.$set(initialAutocompletes, 'crew_member_3', {
          data: peopleData,
          hasMore: hasMore
        })
      }

      if (formDataForSend && formDataForSend.vehicle_id) {
        this.vehicleRegistrationAutocompleteField.pickedId = formDataForSend.vehicle_id
      }
    },

    onDepartureSendForSignature (event) {
      this.$set(this.sendForSignatureDialogData, 'instance', event?.indexPage)
      this.$set(this.sendForSignatureDialogData, 'signatureType', 'PRL_CARGO_DEPARTURE')
      this.$set(this.sendForSignatureDialogData, 'show', true)
    },

    // Departure third party signature
    onDepartureThirdPartySignature (event) {
      const { formData } = event || {}
      if (formData) {
        this.$set(this.thirdPartySignatureDialogConfig, 'title', this.$t('fleet/documents/prl_cargo.add_signatory_information'))
        this.$set(this.thirdPartySignatureDialogConfig, 'signatureType', 'PRL_CARGO_DEPARTURE')
        this.$set(this.thirdPartySignatureDialogConfig, 'apiConfig', {
          module: 'fleet',
          method: 'post',
          route: 'travel-documents/third-party-signature'
        })
        this.$set(this.thirdPartySignatureDialogConfig, 'instance', event?.indexPage)
        this.$set(this.thirdPartySignatureDialogConfig, 'show', true)
      }
    },

    onDepartureSign (event, data, signatureType = '', fetchSignature = false) {
      const config = {
        title: this.$t('fleet/documents/prl_cargo.sign_document'),
        confirmDialogTitle: this.$t('fleet.sign_document'),
        tabsContentInstance: this.tabsContentInstance,
        apiConfig: {
          get: {
            module: 'fleet',
            route: 'travel-documents/get-driver-company-from-user',
            method: 'get'
          },
          post: {
            module: 'fleet',
            route: 'travel-documents/signature',
            method: 'post'
          }
        }
      }
      this.$set(this.signatureDialogData, 'config', config)
      this.$set(this.signatureDialogData, 'fetchSignature', fetchSignature)
      this.$set(this.signatureDialogData, 'signatureType', signatureType)
      this.$set(this.signatureDialogData, 'instance', event?.indexPage)
      this.$set(this.signatureDialogData, 'show', true)
    },

    onArrivalSign (event, data, signatureType = '', fetchSignature = false) {
      const config = {
        title: this.$t('fleet/documents/prl_cargo.sign_document'),
        confirmDialogTitle: this.$t('fleet.sign_document'),
        tabsContentInstance: this.tabsContentInstance,
        apiConfig: {
          get: {
            module: 'fleet',
            route: 'travel-documents/get-driver-company-from-user',
            method: 'get'
          },
          post: {
            module: 'fleet',
            route: 'travel-documents/signature',
            method: 'post'
          }
        }
      }
      this.$set(this.signatureDialogData, 'config', config)
      this.$set(this.signatureDialogData, 'fetchSignature', fetchSignature)
      this.$set(this.signatureDialogData, 'signatureType', signatureType)
      this.$set(this.signatureDialogData, 'instance', event?.indexPage)
      this.$set(this.signatureDialogData, 'show', true)
    },

    onFuelSign (event, data, signatureType = '', fetchSignature = false) {
      const config = {
        title: this.$t('fleet/documents/prl_cargo.sign_document'),
        confirmDialogTitle: this.$t('fleet.sign_document'),
        tabsContentInstance: this.tabsContentInstance,
        apiConfig: {
          get: {
            module: 'fleet',
            route: 'travel-documents/get-driver-company-from-user',
            method: 'get'
          },
          post: {
            module: 'fleet',
            route: 'travel-documents/signature',
            method: 'post'
          }
        }
      }
      this.$set(this.signatureDialogData, 'config', config)
      this.$set(this.signatureDialogData, 'fetchSignature', fetchSignature)
      this.$set(this.signatureDialogData, 'signatureType', signatureType)
      this.$set(this.signatureDialogData, 'instance', event?.instance?.$parent?.$parent?.$parent)
      this.$set(this.signatureDialogData, 'show', true)
    },

    onArrivalSendForSignature (event) {
      this.$set(this.sendForSignatureDialogData, 'signatureType', 'PRL_CARGO_ARRIVAL')
      this.$set(this.sendForSignatureDialogData, 'instance', event?.indexPage)
      this.$set(this.sendForSignatureDialogData, 'show', true)
    },

    // Arrival third party signature
    onArrivalThirdPartySignature (event) {
      const { formData } = event || {}
      if (formData) {
        this.$set(this.thirdPartySignatureDialogConfig, 'title', this.$t('fleet/documents/prl_cargo.add_signatory_information'))
        this.$set(this.thirdPartySignatureDialogConfig, 'signatureType', 'PRL_CARGO_ARRIVAL')
        this.$set(this.thirdPartySignatureDialogConfig, 'apiConfig', {
          module: 'fleet',
          method: 'post',
          route: 'travel-documents/third-party-signature'
        })
        this.$set(this.thirdPartySignatureDialogConfig, 'instance', event?.indexPage)
        this.$set(this.thirdPartySignatureDialogConfig, 'show', true)
      }
    },

    async closeSignatureDialog () {
      this.$set(this.signatureDialogData, 'show', false)
    },

    async closeThirdPartySignatureDialog () {
      this.$set(this.thirdPartySignatureDialogConfig, 'show', false)
    },

    openSignatureDialog (event, data, signatureType = '', fetchSignature = false) {
      const config = {
        title: this.$t('fleet/documents/prl_cargo.sign_document'),
        confirmDialogTitle: this.$t('fleet.sign_document'),
        tabsContentInstance: this.tabsContentInstance,
        apiConfig: {
          get: {
            module: 'fleet',
            route: 'travel-documents/get-driver-company-from-user',
            method: 'get'
          },
          post: {
            module: 'fleet',
            route: 'travel-documents/signature',
            method: 'post'
          }
        }
      }
      this.$set(this.signatureDialogData, 'config', config)
      this.$set(this.signatureDialogData, 'fetchSignature', fetchSignature)
      this.$set(this.signatureDialogData, 'signatureType', signatureType)
      this.$set(this.signatureDialogData, 'instance', event?.indexPage)
      this.$set(this.signatureDialogData, 'show', true)
    },

    handleAdditionalDataManipulation (data) {
      const {
        crew_member_1: crewMember1,
        crew_member_2: crewMember2,
        crew_member_3: crewMember3
      } = data || {}

      const crewMemberIds = [crewMember1, crewMember2, crewMember3].filter(Boolean)

      // Add the array only if at least one member exists
      if (crewMemberIds.length) {
        data.crew_members = crewMemberIds
        this.$delete(data, 'crew_member_1')
        this.$delete(data, 'crew_member_2')
        this.$delete(data, 'crew_member_3')
      }
    },

    onBasicInfoViewPdf (event) {
    },

    onVehicleMovementViewPdf (event) {
    },

    onPlannedViewPdf (event) {
    },

    async onChangeStatus (event) {
      const { formDataForSend } = this.tabsContentInstance || {}
      const { id } = formDataForSend?.status || {}
      if (this.changeStatusDialogConfig.statuses.length) {
        this.$set(this.changeStatusDialogConfig, 'current_status', id)
        this.$set(this.changeStatusDialogConfig, 'document_id', this.$route.params.id)
        this.$set(this.changeStatusDialogConfig, 'instance', event?.indexPage)
        this.$set(this.changeStatusDialogConfig, 'show', true)
      }
      else {
        const { method, module, route } = this.changeStatusDialogConfig?.getStatusesApiConfig || {}

        if (method && module && route) {
          const { data } = await api()[module][method](route)
          if (data && data.length) {
            this.$set(this.changeStatusDialogConfig, 'statuses', data)
            this.$set(this.changeStatusDialogConfig, 'current_status', id)
            this.$set(this.changeStatusDialogConfig, 'document_id', this.$route.params.id)
            this.$set(this.changeStatusDialogConfig, 'instance', event?.indexPage)
            this.$set(this.changeStatusDialogConfig, 'show', true)
          }
        }
      }
    },

    async closeChangeStatusDialog () {
      this.$set(this.changeStatusDialogConfig, 'show', false)
    }
  }
}
</script>
