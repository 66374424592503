<template>
  <index-view
    view-name="fleet"
    row-pointer
    :module="module"
    :model="model"
    :list="list"
    :show-resource-route-name="'FleetVehicleView'"
    :pagination="pagination"
    :sort="sort"
    :view-config="viewConfig"
    :filters-cache="filtersCache"
    :set-filters-cache-key="setFiltersCacheKey"
    :reset-filters-cache="resetFiltersCache"
    :fetch="fetch"
    :top-buttons="topButtons"
    :showLoader="loader"
  />
</template>

<script>
import indexView from '@/global/components/Index'
import { createNamespacedHelpers } from 'vuex'
import { api } from '@/global/services/api'

const {
  mapGetters: mapGettersConfig
} = createNamespacedHelpers('base/config')

const { mapGetters, mapActions } = createNamespacedHelpers('fleet/vehicles')

export default {
  name: 'FleetVehiclesView',

  components: {
    'index-view': indexView
  },

  props: {
    module: {
      type: String,
      default: 'fleet'
    },
    model: {
      type: String,
      default: 'vehicles'
    }
  },

  data () {
    return {
      loading: false
    }
  },

  computed: {
    ...mapGetters([
      'list',
      'pagination',
      'sort',
      'filtersCache',
      'viewConfig'
    ]),
    ...mapGettersConfig([
      'isMaster',
      'canEditTrackers'
    ]),
    topButtons: function () {
      const that = this

      return [
        {
          name: 'Process restart',
          icon: 'mdi-restart',
          handler: async function () {
            that.loading = true
            try {
              const response = await api().base.get('process/restart', {
                scope: 'vehicles_overview'
              })

              if (!response.data || !response.data.success) {
                console.log(response)
              }
            }
            catch (err) {
              console.log('err', err)
            }

            that.loading = false
          },
          visible: this.canEditTrackers
        }
      ]
    },
    loader: function () {
      return this.loading
    }
  },

  methods: {
    ...mapActions([
      'fetch',
      'setFiltersCacheKey',
      'resetFiltersCache'
    ])
  }
}
</script>
