<template>
  <index-view
    view-name="vehicle_tags"
    :module="module"
    :model="model"
    :list="list"
    :pagination="pagination"
    :sort="sort"
    :view-config="viewConfig"
    :fetch="fetch"
  />
</template>

<script>
import indexView from '@/global/components/Index'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('fleet/vehicle-tags')

export default {
  name: 'VehicleTagsView',

  components: {
    'index-view': indexView
  },

  props: {
    module: {
      type: String,
      default: 'fleet'
    },
    model: {
      type: String,
      default: 'vehicle-tags'
    }
  },

  computed: {
    ...mapGetters(['list', 'pagination', 'sort', 'viewConfig'])
  },

  methods: {
    ...mapActions(['fetch'])
  }
}
</script>
