<template>
<div>
  <v-dialog
    v-model="canShowDialog"
    content-class="elevation-0"
    width="560"
    persistent
  >
    <v-card
      class="v-card"
    >
      <v-card-title
        class="v-card-title"
      >
        {{ title ? title : '' }}
        <v-spacer/>

        <universal-button
          type="base"
          fab
          x-small
          color="#6B7280"
          style="border-radius: 40px;"
          append-icon="mdi-close"
          appendIconColor="#FFFFFF"
          @click="closeDialog"
        />
      </v-card-title>

      <v-card-text
        class="v-card-text"
      >
        <v-row
          style="margin: 0;"
        >
          <v-col>
            <text-field
              v-model="name"
              :field="nameField"
              :validation-errors="validationErrors"
              @clear-validation-errors="$delete(validationErrors, 'name')"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions
        class="v-card-actions"
      >
        <v-row
          style="margin: 0;"
        >
          <v-col>
            <universal-button
              type="cancel"
              class="universal-buttons"
              @click="closeDialog"
            />
          </v-col>
          <v-col>
            <universal-button
              type="save"
              :label="$t('base.continue')"
              class="universal-buttons"
              @click="onContinue"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <create-signature-dialog
    v-if="showCreateSignatureDialog"
    :show-dialog="showCreateSignatureDialog"
    :config="{}"
    :show-save-checkbox="false"
    :third-party-signature="true"
    @confirm="sendSignature"
    @close="closeSignatureDialog"
  />
</div>
</template>

<script>
import CreateSignatureDialog from './CreateSignatureDialog.vue'
import UniversalButton from '@/global/components/buttons/UniversalButton.vue'
import TextField from '@/global/components/view-layouts/tabs-layout/components/fields/TextField.vue'
import { isEmpty } from 'lodash'
import { api } from '@/global/services/api'

export default {
  name: 'ThirdPartySignature',

  components: {
    TextField,
    UniversalButton,
    CreateSignatureDialog
  },

  emits: [
    'close',
    'refresh-instance'
  ],

  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    apiConfig: {
      type: Object,
      default: () => ({})
    },
    documentId: {
      type: [Number, String],
      default: null
    },
    signatureType: {
      type: String,
      default: ''
    },
    additionalObject: {
      type: Object,
      default: () => ({})
    },
    instance: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      canShowDialog: false,
      nameField: {
        key: 'name',
        label: this.$t('fleet/documents/prl_cargo.name_and_surname')
      },
      name: '',
      validationErrors: {},
      showCreateSignatureDialog: false
    }
  },

  watch: {
    showDialog: {
      immediate: true,
      handler (val) {
        this.canShowDialog = val
      }
    }
  },

  methods: {
    closeDialog () {
      this.$emit('close')
    },

    closeSignatureDialog () {
      this.showCreateSignatureDialog = false
    },

    onContinue () {
      if (this.name) {
        this.showCreateSignatureDialog = true
      }
      if (!this.name) {
        this.$set(this.validationErrors, 'first_name', this.$t('fleet/documents/prl_cargo.first_name_mandatory'))
      }
    },

    async sendSignature (data) {
      try {
        if (isEmpty(this.apiConfig) || !data) {
          return
        }

        const { module, method, route } = this.apiConfig || {}

        if (module && method && route) {
          const formData = new FormData()
          formData.append('signature_name', this.name)
          formData.append('signature_image', data)
          formData.append('signature_type', this.signatureType)
          formData.append('travel_document_id', this.documentId)

          if (!isEmpty(this.additionalObject)) {
            for (const key in this.additionalObject) {
              formData.append(key, this.additionalObject[key] ?? '')
            }
          }

          await api()[module][method](route, formData)
          this.showCreateSignatureDialog = false
          this.$emit('close', true)
          if (this.instance) {
            this.$emit('refresh-instance', this.instance)
          }
        }
      }
      catch (exception) {
        console.log(exception)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.universal-buttons {
  width: 100%;
  height: 40px !important;
}

.v-card-title {
  font-family: 'Satoshi', sans-serif;
  font-weight: 500;
  font-size: 24px;
  border-bottom: 1px solid #E5E7EB;
}
</style>
