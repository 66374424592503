<template>
  <v-container
    class="fill-height"
  >
    <v-row class="no-gutters elevation-4 fill-height white">
      <v-col
        cols="12"
        sm="3"
        class="flex-grow-1 flex-shrink-0"
        style="border-right: 1px solid #0000001f;"
      >
        <v-responsive
          class="overflow-y-auto chat-height"
        >
          <v-text-field
            filled
            :label="$t('fleet/communication.find') + '...'"
            append-icon="mdi-magnify"
            hide-details
          />
          <v-list
            two-line
          >
            <v-list-item-group
              v-model="selectedChatter"
              active-class="blue lighten-4"
            >
              <template v-for="(chatter, index) in chatters">
                <v-list-item :key="chatter.name">
                  <v-badge
                    :content="chatter.unread_msg_count"
                    :value="chatter.unread_msg_count"
                    bordered
                    bottom
                    left
                    overlap
                    color="accent"
                    offset-x="22"
                    offset-y="32"
                  >
                    <v-list-item-avatar>
                      <v-img :src="chatter.logo || '/img/icons/avatar.jpg'" />
                    </v-list-item-avatar>
                  </v-badge>
                  <template>
                    <v-list-item-content>
                      <v-list-item-title v-text="chatter.registration + ' - ' + chatter.label" />

                      <v-list-item-subtitle v-text="chatter.driver" />
                    </v-list-item-content>
                  </template>
                </v-list-item>

                <v-divider
                  v-if="index < chatters.length - 1"
                  :key="index"
                />
              </template>
            </v-list-item-group>
          </v-list>
        </v-responsive>
      </v-col>
      <v-col
        sm="9"
        class="flex-grow-1 flex-shrink-0"
      >
        <v-responsive
          v-if="activeChat"
          class="overflow-y-hidden chat-height"
        >
          <v-card
            flat
            class="d-flex flex-column fill-height"
          >
            <v-card-text
              id="messages_container"
              class="flex-grow-1 overflow-y-auto d-flex flex-column-reverse"
              @scroll="loadMoreMessages"
            >
              <div
                v-for="(message, index) in latestMessages"
                :key="'message-' + message.vehicle_id + '-' + index"
              >
                <v-row v-if="shouldShowDateLabel(message.created_at, index)">
                  <v-col class="text-center my-2 font-weight-bold">
                    {{ getDateFromDateTimeString(message.created_at) }}
                  </v-col>
                </v-row>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="auto">
                        <v-avatar
                          size="36px"
                        >
                          <img
                            alt="Avatar"
                            :src="getAvatarImage(message.vehicle_id, message.is_own_message)"
                          >
                        </v-avatar>
                      </v-col>
                      <v-col>
                        <div class="text--primary">
                          <span class="font-weight-bold mr-7">
                            {{ message.is_own_message ? user.full_name : message.driver }}
                          </span>
                          <span class="caption">
                            {{ getTimeFromDateTimeString(message.created_at) }}
                          </span>
                        </div>
                        <div
                          v-if="message.type === 'TEXT'"
                          class="mb-1"
                          v-text="message.content.msg"
                        />
                        <div
                          v-else-if="message.type === 'LOCATION'"
                          :key="`reverse-geocoding-wrapper-${message.vehicleId}-${message.content.latitude}-${message.content.longitude}`"
                        >
                          <a
                            href="#"
                            @click.prevent="showOrToggleLocationMapForMessage(message)"
                          >
                            <reverse-geocoding-field
                              :key="`reverse-geocoding-field-${message.vehicleId}-${message.content.latitude}-${message.content.longitude}`"
                              :lat-lng="{ lat: message.content.latitude, lng: message.content.longitude }"
                              :vehicle-id="message.vehicle_id"
                              :text-alignment="'left'"
                              class="mb-1"
                            />
                          </a>
                          <communication-geolocation-map
                            v-if="showMapForMessageAndVehicleId(message)"
                            :provided-geolocation="{ lat: message.content.latitude, lng: message.content.longitude}"
                            :zoom-on="latLng(parseFloat(message.content.latitude), parseFloat(message.content.longitude))"
                            :initial-zoom="16"
                            view-only-mode
                          />
                        </div>
                        <v-row>
                          <v-col class="text-right">
                            <div
                              v-if="!message.is_own_message && message.read_at"
                              class="caption"
                            >
                              {{ $t('fleet/communication.seen') }}: {{ getDateTimeFromDateTimeString(message.read_at) }}
                            </div>
                            <div
                              v-else-if="message.is_own_message"
                              class="caption"
                            >
                              {{ $t('fleet/communication.sent') }}: {{ getDateTimeFromDateTimeString(message.created_at) }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </div>
            </v-card-text>
            <!-- Send message area -->
            <v-card-text class="flex-shrink-1">
              <v-tabs>
                <v-tab
                  class="communication-tab"
                  active-class="communication-tab-active"
                >
                  <v-icon class="mr-1">
                    mdi-email-outline
                  </v-icon>
                  {{ $t('fleet/communication.message') }}
                </v-tab>
                <v-tab
                  class="communication-tab"
                  active-class="communication-tab-active"
                >
                  <v-icon>mdi-map-marker</v-icon>
                  {{ $t('fleet/communication.location') }}
                </v-tab>
                <v-tab-item>
                  <v-textarea
                    v-model="messageContent"
                    :label="$t('fleet/communication.type_message_text') + '...'"
                    type="text"
                    color="primary"
                    class="py-2"
                    no-details
                    outlined
                    no-resize
                    hide-details
                    @keyup.enter="sendMessage('TEXT')"
                  >
                    <template
                      #append
                      class="align-self-end mb-2"
                    >
                      <v-icon
                        color="accent"
                        @click="sendMessage('TEXT')"
                      >
                        mdi-send
                      </v-icon>
                    </template>
                  </v-textarea>
                </v-tab-item>
                <v-tab-item>
                  <v-row class="map-wrapper">
                    <v-col>
                      <communication-geolocation-map @pinPlaced="pinnedGeolocation = $event[0].coordinate" />
                    </v-col>
                    <v-col
                      align-self="end"
                      cols="auto"
                      class="mb-3 mr-3 pl-0"
                    >
                      <v-icon
                        color="accent"
                        @click="sendMessage('LOCATION')"
                      >
                        mdi-send
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs>
            </v-card-text>
            <!-- ./ Send message area -->
          </v-card>
        </v-responsive>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from 'dayjs'
import { latLng } from 'leaflet'
import ReverseGeocodingField from '@/global/components/geocoding/ReverseGeocodingField'
import communicationGeolocationMap
  from '@/modules/fleet-module/communication/components/CommunicationGeolocationMap'
import { createNamespacedHelpers } from 'vuex'
import store from '@/global/store'
import { api } from '@/global/services/api'

const {
  mapGetters: mapGettersBase
} = createNamespacedHelpers('base/config')

export default {
  name: 'Communication',

  components: {
    communicationGeolocationMap,
    ReverseGeocodingField
  },

  data: () => ({
    activeChat: 1,
    selectedChatter: null,
    chatters: [],
    latestMessages: [],
    messageContent: '',
    module: 'fleet',
    pinnedGeolocation: null,
    showAddressOnMap: null,
    showMapForMessageAndVehicleIdData: {
      messageId: null,
      vehicleId: null
    }
  }),

  computed: {
    ...mapGettersBase(['user'])
  },

  watch: {
    selectedChatter (value) {
      this.getLatestMessagesForChatter(0)
    }
  },

  mounted () {
    this.getChatters()
  },

  methods: {
    showOrToggleLocationMapForMessage (messageData) {
      if (this.showMapForMessageAndVehicleId(messageData)) {
        this.$set(this.showMapForMessageAndVehicleIdData, 'messageId', null)
        this.$set(this.showMapForMessageAndVehicleIdData, 'vehicleId', null)
      }
      else {
        this.$set(this.showMapForMessageAndVehicleIdData, 'messageId', messageData.id)
        this.$set(this.showMapForMessageAndVehicleIdData, 'vehicleId', messageData.vehicle_id)
      }
    },
    showMapForMessageAndVehicleId (messageData) {
      return this.showMapForMessageAndVehicleIdData.vehicleId === messageData.vehicle_id &&
          this.showMapForMessageAndVehicleIdData.messageId === messageData.id
    },
    latLng,
    getDateFromDateTimeString (date) {
      return dayjs(date).format('DD/MM/YYYY')
    },
    getDateTimeFromDateTimeString (date) {
      return dayjs(date).format('DD/MM/YYYY HH:mm')
    },
    getTimeFromDateTimeString (date) {
      return dayjs(date).format('HH:mm')
    },
    shouldShowDateLabel (createdAt, index) {
      if (index === this.latestMessages.length - 1) return true
      return this.latestMessages[index + 1] &&
        dayjs(createdAt).format('DD/MM/YYYY') !== dayjs(this.latestMessages[index + 1].created_at).format('DD/MM/YYYY')
    },
    async getLatestMessagesForChatter (offset = 0, loadAdditionalMessages = false) {
      if (this.selectedChatter === undefined) {
        this.latestMessages = []
      }
      else {
        const vehicleId = (this.chatters[this.selectedChatter].vehicle_id)
        await api()[this.module].get('vehicle-messages/get-latest', { vehicle_id: vehicleId, offset: offset })
          .then((response) => {
            if (loadAdditionalMessages) {
              this.latestMessages.push(...response.data)
            }
            else {
              this.latestMessages = response.data
            }
            this.checkForUnreadMessages()
          })
          .catch((error) => {
            this.handleApiResponseError(error)
          })
      }
    },
    handleApiResponseError (error) {
      if ('message' in error) {
        store.dispatch(
          'base/notifications/push',
          error.response.data?.errors?.content ||
          error.response.data?.message ||
          error.message
        )
      }
    },
    checkForUnreadMessages () {
      // if there are any unread message make them as read
      setTimeout(async () => {
        if (this.latestMessages.some(message => !message.is_own_message && message.read_at === null)) {
          const unreadMessageIds = this.latestMessages.reduce((ids, message) => {
            if (!message.is_own_message && message.read_at === null) {
              ids.push(message.id)
            }
            return ids
          }, [])

          await api()[this.module].post('vehicle-messages/notify-read', { ids: unreadMessageIds })
            .then((response) => {
              // update unread messages time
              this.latestMessages.forEach(message => {
                if (!message.is_own_message && !message.read_at) {
                  message.read_at = response.data
                }
              })
              // update unread message counter
              this.chatters[this.selectedChatter].unread_msg_count = this.chatters[this.selectedChatter].unread_msg_count - unreadMessageIds.length
            })
            .catch((error) => {
              this.handleApiResponseError(error)
            })
        }
      }, 1000)
    },
    loadMoreMessages () {
      const messagesContainer = document.getElementById('messages_container')
      // if user scroll to the top of the chat load more messages
      if (messagesContainer.offsetHeight + Math.abs(messagesContainer.scrollTop) === messagesContainer.scrollHeight) {
        this.getLatestMessagesForChatter(this.latestMessages.length, true)
      }
    },
    async getChatters () {
      await api()[this.module].get('vehicle-messages/get-vehicles')
        .then((response) => {
          this.chatters = response.data
        })
        .catch((error) => {
          this.handleApiResponseError(error)
        })
    },
    getAvatarImage (vehicleId, isOwnMessage) {
      return isOwnMessage
        ? this.user.avatarUrl || 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp'
        : this.chatters.find(chatter => chatter.vehicle_id === vehicleId)?.logo || 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp'
    },
    async sendMessage (messageType) {
      if (this.selectedChatter !== null) {
        const messageForm = this.createMessageForm(messageType)
        await api()[this.module].post('vehicle-messages/send', messageForm)
          .then((response) => {
            this.latestMessages.unshift(response.data)
            this.messageContent = ''
          })
          .catch((error) => {
            this.handleApiResponseError(error)
          })
      }
      else {
        store.dispatch('base/notifications/push', this.$t('fleet/communication.no_selected_chatter_warning'))
      }
    },
    createMessageForm (messageType) {
      const messageForm = {}

      messageForm.vehicle_id = this.chatters[this.selectedChatter].vehicle_id
      messageForm.type = messageType === 'TEXT' ? 'TEXT' : 'LOCATION'
      messageForm.content = messageType === 'TEXT'
        ? { msg: this.messageContent }
        : {
          latitude: this.pinnedGeolocation?.lat,
          longitude: this.pinnedGeolocation?.lng
        }

      messageForm.created_at = dayjs().format('HH:mm')
      return messageForm
    }
  }
}
</script>

<style scoped lang="scss">
.chat-height {
  height: calc(100vh - 90px)
}
::v-deep div.v-input__append-inner {
  align-self: flex-end;
  margin-bottom: 0.4rem;
}

.communication-tab {
  text-transform: none !important;
  border-bottom: 4px solid transparent;
}

.communication-tab-active {
  background: #fff !important;
  border-bottom: 3px solid rgb(81, 173, 70);
}
</style>
